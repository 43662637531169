import { ReactEvt } from "./utils";

export interface DhNotification {
    id: number;
    time: string;
    highlight: string;
    header: string;
    text: string;
}

export class Notifications {
    nlist: DhNotification[] = [];
    visible: DhNotification[] = [];
    lastNo = 0;
    revt = new ReactEvt( ()=>this.visible );

    add( notif ) {
        notif.time = (new Date()).toISOString();
        notif.id = this.lastNo++;
        if ( this.nlist.length >= 500 ) {
            this.nlist = [ ...this.nlist.slice( 1 ), notif ];
        } else {
            this.nlist.push( notif );
        }

        this.visible = [ ...this.visible, notif ];
        this.revt.notify();

        setTimeout( ()=>this.onDestroy( notif.id ), 10000 );
    }

    onDestroy( id ) {
        this.visible = this.visible.filter( n => n.id != id );
        this.revt.notify();
    }
}