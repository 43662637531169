import { SingleEvent, ReactEvt, utils, Lock } from '../utils';
import { TypeInfo } from '../TypeInfo';
import { PathInfo } from '../PathInfo';
import { Application } from '../App';
import { FormInfo, FormLayout } from '../Interfaces';
import { render } from 'react-dom';
import { PageComponent } from '../elems/PageComponent';
import { createContext } from 'react';
import { DIALOG_ACTIONS } from '../constants';

declare var app: Application;

/** Базовый класс для всех вкладок и диалогов */
export class Page {
    pageId: number;
    pageUid: number;
    props: any;
    mode: string;
    title: string;

    // options === props
    get options() { return this.props; }
    readyEvt = new SingleEvent();
    get ready() { return this.readyEvt.done };
    loadingCounter = 0;
    get loading() { return this.isLoading(); }
    docId?: any;
    docName?: string;
    // docData?: any;
    // updates?: any;
    typeName: string;
    viewMode: string;
    valueMode: string;
    path: string;
    formName?: string;
    subforms: any;
//    state: any;
    error: string | undefined;
    _isDialog: boolean;

    closed: boolean = false;
    typeInfo?: TypeInfo;
    pathInfo?: PathInfo;
    get typeDesc() { return this.typeInfo; }
    formInfo?: FormInfo;
    defaultFormName? : string = "ViewForm";
    allActions: any[] = [];
    visible = false;
    fixed = false;

    titleEvt: ReactEvt;
    _baseTitle: string = "";

    pageEvt: ReactEvt;

    actionLock = new Lock();

    printWindow?: Window;

    constructor( props ) {
        this.pageUid =  app.newPageId();
        this.props = props;
        this.pageId = props.pageId || this.pageUid;
        this.mode = props.mode;
        this.typeName = props.typeName;
        this.viewMode = props.viewMode;
        this.valueMode = props.valueMode;
        this.path = props.path;
        this.formName = props.formName;
        this.subforms = {};
        this._isDialog = props.target === "dialog";
        this.title = props.title || "DocHub";

        this.titleEvt = new ReactEvt( ()=>this.getTitle() );
        this.pageEvt = new ReactEvt( ()=>this.getState() );

        if ( props.actions )
            this.setActions( props.actions );

        setTimeout( ()=>this.init(), 1 );
    }

    init() {
        if ( this._isDialog && this.allActions.length == 0 )
            this.setActions( DIALOG_ACTIONS );

        this.readyEvt.set();
        this.prepareTitle();
        this.notify();

        if ( typeof this.props.onReady === "function" )
            this.props.onReady( this );
    }
    
    /** Инициализация - вызывается в конце конструктора производного класса, если требуется */
    initType() {
        if ( this.path ) {
            this.pathInfo = app.getPathInfo( this.path );
        }

        if ( this.typeName == null && this.pathInfo?.typeName )
            this.setTypeName( this.pathInfo.typeName );
        else if ( this.typeName && !this.typeInfo ) 
            this.setTypeName( this.typeName );
    }

    setTypeName( typeName: string ) {
        this.typeName = typeName;
        this.typeInfo = app.getType( this.typeName );
        if ( this.typeInfo?.onPageInit )
            this.typeInfo.onPageInit( this );

        if ( this.formName == null )
            this.formName = this.typeInfo?.formName || this.defaultFormName;

        if ( this.formName )
            this.formInfo = app.getForm( this.formName );
    }

    getTitle() : string {
        return this.title;
    }

    /** Формирование части URL для данной страницы */
    getUrl() {
        return "";
    }

    /** ИД для замены новой страницей */
    getReplaceKey() : string | undefined {
        return undefined;
    }

    stick() {
        this.fixed = true;
    }

    setVisible() {
        this.visible = true;
    }

    setHidden() {
        this.visible = false;
    }

    getDoc() : any {
        return null;
    }

    getUpdates() {
        return null;
    }

    isLoading() {
        return !this.ready || this.loadingCounter > 0;
    }

    incLoading() {
        if ( ++this.loadingCounter == 1 ) {
            this.notify();
        }
    }

    decLoading() {
        if ( --this.loadingCounter <= 0 ) {
            this.notify();
        }
    }
    
    /** Запрос доступных действий с сервера */
    async requestActions() {
        if ( this.isSubform() || !app.session || this.props.actions )
            return;

        let params = {
            path: this.path,
            form: this.formName,
            type: this.typeName,
            mode: this.mode,
            viewMode: this.viewMode,
            docId: this.docId,
            docName: this.docName
        };


        try {
            let res = await app.docSvc.getActions( params );
            this.setActions( res.actions );
        } catch ( err ) {
            console.error( err );
            this.showError( "Ошибка запроса доступных действий");
        }
    }

    setActions( actions ) {
        if ( !Array.isArray( actions ) ) throw Error( "Invalid actions array" );
        this.allActions = actions;
        this.allActions.forEach( a => { 
            a.icon = utils.fixIcon( a.icon );
            if ( a.place === "dialogCommands" )
                a.place = "dialog";
         } );
        this.notify();
    }
    
    /** Формирование активных действий для отображения кнопок на панели */
    getCurrentActions() {
        if ( !this.allActions || this.allActions.length == 0 )
            return [];

        return this.allActions.filter( a => this.matchCondition( a.visible ) );
    }
    
    /** Обработка уведомления об изменении данных. Вызов в классе App.
     * @param {Object} what Описание изменений
     */
    onSomethingChange( what ) {}
    
    /** Показать всплывающее окно и красный текст ошибки */
    showError( text ) {
        if ( typeof text === 'object' && text.message )
            text = text.message;

        app.showError( text );
        this.setError( text );
    }

    /** Показать красный текст ошибки */
    setError( text ) {
        this.error = text;
        this.notify();
    }

    /** Удалить красный текст ошибки */
    clearError() {
        delete this.error;
    }

    onShowPage() {}
    onHidePage() {}

    /** Закрыть вкладку/диалог */
    close() {
        if ( !this.closed && this.props.onClose )
            this.props.onClose( this );

        this.closed = true;
        
        if ( this.props.close )
            this.props.close();
        else {
            app.closePage( this );
        }
    }

    // Для совместимости
    onClose() { this.close(); }

    isEasyClose() { return false; }

    isDialog() {
        return this._isDialog;
    }
    
    isSubform() {
        return this.props.target === "subform";
    }

    // TODO
    // setTarget( t ) {
    // }

    private _getBaseTitle() {
        if ( this.props.title != null )
            return this.props.title;

        let td = this.typeInfo?.desc;
        let pd = this.pathInfo?.desc;

        if ( this.props.mode == "list" ) {
            let t = pd?.titles?.list;
            if ( t ) return t;

            t = pd?.titleList;
            if ( t ) return t;

            t = td?.titles?.list;
            if ( t ) return t;

            t = td?.titleList;
            if ( t ) return t;

            t = pd?.label;
            if ( t ) return t;
        } else {
            let t = pd?.titles?.show;
            if ( t ) return t;

            t = pd?.titleShow;
            if ( t ) return t;

            t = td?.titles?.show;
            if ( t ) return t;

            t = td?.titleShow;
            if ( t ) return t;

            t = td?.label;
            if ( t ) return t;
        }

        return "DocHub";
    }

    _calcTitle() {
        return this._baseTitle.replace( /\{(.*?)\}/g, (m,p1) => this.evalScript(p1, null, false ) );
    }

    /** Формирования заголовка */
    prepareTitle() {
        let title = this._baseTitle = this._getBaseTitle();

        // TODO: use this.typeInfo?.titleColorField;

        if ( /\{(.*?)\}/.test( title ) ) {
            // Вычисляемый заголовок
            this.titleEvt.getState = ()=>this._calcTitle();
            this.title = this._calcTitle();
        } else {
            this.titleEvt.getState = ()=>this.title;
            this.title = title;
        }
        this.titleEvt.notify();
    }

    /** Форма в режиме редактирования? */
    isEditMode() { return false; }

    /** Формирование React-компонент для данной страницы */
    // render() {
    //     return <PageComponent key={this.pageUid} page={this}/>;
    // }

    /** Вызов заданного действия */
    async startAction( action, params, hideResult ) {
        return this.actionLock.exec( ()=>this.doStartAction( action, params, hideResult ) );
    }

    async doStartAction( action, params, hideResult ) {
        if ( typeof action === "string" ) {
            if ( this.allActions ) {
                action = this.allActions.find( a => a.name === action );
            }
        }
        
        if ( action == null || typeof action !== 'object' ) {
            this.showError( "Invalid action: " + action );
            return false;
        }

        if ( params == null )
            params = {};

        if ( action.beforeStart ) {
            let res = await this.evalScriptAsync( action.beforeStart, params, hideResult );
            // console.log( "beforeStart result:", res);
            if (res === false) return false;
        }

        let hasProcess = false;
        
        if ( action.script ) {
            let res;
            if ( action.script.indexOf(" process(") != -1 ) {
                hasProcess = true;
                let params2 = { 
                    ...params, 
                    process: (arg, hideResult)=>this.executeAction( action, arg, hideResult == null ? true : hideResult )
                };
                res = await this.evalScriptAsync( action.script, params2, hideResult );
            } else {
                res = await this.evalScriptAsync( action.script, params, hideResult );
            }

            if (res === false) return false;
        }

        if ( !hasProcess && (action.nextStatus || action.serverFunction) ) {
            let res = await this.executeAction( action, params, hideResult );
            if ( !res )
                return false;

            if ( typeof res === "object" )
                params = res.result;
        }
        
        if ( action.onSuccess ) {
            await this.evalScriptAsync( action.onSuccess, params, hideResult );
        }
        return true;
    }

    /** @private 
     * Отправка запроса на сервер для выполнения действия
     */
    async executeAction( action, params, hideResult ) {
        let doc = this.getDoc();
        let updates = this.getUpdates();
//        let selected;

        let req = {
            actionName: action.name,
            path: this.path,
            form: this.formName,
            type: this.typeName,
            mode: this.mode,
            viewMode: this.viewMode,
            docId: this.docId,
            docName: this.docName,
            doc: doc,
            updates: updates,
            params: params
        };

        if ( hideResult && !params?.showResult )
            return app.docSvc.startAction( req );

        try {
            let res = await app.docSvc.startAction( req )

            if ( res.result !== false ) {
                if ( res.resultText )
                    app.showSuccess( res.resultText );
                else
                    app.showSuccess( "OK" );

                return true;
            } else {
                if ( res.resultText )
                    app.showError( res.resultText );
                else
                    app.showError( "Error" );

                return false;
            }
        } catch ( error : any ) {
            console.error( error );
            app.showError( error.message );
            return false;
        }
    }
    
    /** Выполнение скрипта в контексте текущей страницы */
    evalScript( script, params?, showError? ) {
        let page = this;
        let model = this;
        let typeInfo = this.typeInfo;
        let pathInfo = this.pathInfo;
        let formInfo = this.formInfo;
        let doc = this.getDoc() || {};
        let updates = this.getUpdates();

        try {
            if ( /\breturn\b/.test( script ) )
                return eval( `(()=>{ ${script} })()` );

            return eval( script );
        } catch ( err: any ) {
            console.error( err );
            if ( showError )
                app.showWarning( String( err.message || "Ошибка выполнения скрипта" ) );
            else
                console.warn( err.message );
        }
    }

    async evalScriptAsync( script, params?, showError? ) {
        let page = this;
        let model = this;
        let typeInfo = this.typeInfo;
        let pathInfo = this.pathInfo;
        let formInfo = this.formInfo;
        let doc = this.getDoc() || {};
        let updates = this.getUpdates();

        let process = params.process;

        try {
            // Если есть await, то и return должен быть
            let _has_a = /\bawait\b/.test( script );
            if ( _has_a ) {
                return await eval( `(async ()=>{ ${script} })()` );
            }
    
            let _has_r = /\breturn\b/.test( script );
            if ( _has_r )
                return eval( `(()=>{ ${script} })()` );
    
            // Простой скрипт - получаем последнее значение
            return eval( script );
        } catch ( err: any ) {
            console.error( err );
            if ( showError )
                app.showWarning( String( err.message || "Ошибка выполнения скрипта" ) );
            else
                console.log( err.message );
            return false;
        }
    }

    /** Добавить страницу как вложенную форму 
     * @param {Page} subform Форма
    */
    addSubform( subform ) {
        this.subforms[ subform.pageId ] = subform;
        subform.target = "subform";
    }
    
    /** Удалить все подформы */
    clearSubforms() {
        this.subforms = {};
    }

    /** Проверка условия - используется для проверки видимости кнопок */
    matchCondition( cond ) {
        if ( cond == null )
            return true;

        if ( typeof cond == "boolean" )
            return cond;
        
        if ( typeof cond == "string" ) {
            if ( cond === "true" || cond === "" ) return true;
            if ( cond === "false" ) return false;
            return this.evalScript( cond ) || false;
        }

        for ( let key in cond ) {
            switch( key ) {
                case "editMode":
                    if ( this.isEditMode() != utils.isTrue(cond[key]) )
                        return false;
                    break;

                case "script":
                    if ( ! this.evalScript( cond.script ) )
                        return  false;
                    break;
            }
        }
        return true;
    }
    
    /** Обработка действия по кнопке "OK" в диалоге */
    onOk() {
        this.close();
    }

    afterClose() {
        this.closed = true;
    }

    /** Открыть новое окно и вывести туда форму для печати */
    openPrintWindow( params ) {
        let url = window.location.href.split("?")[0] + "printable.html";
        let w2 = window.open( url, '_blank' ) as Window;
        if ( w2 == null ) {
            app.showWarning( "Не получается открыть новое окно" );
            return;
        }

        this.printWindow = w2;

        let startTime = Date.now();

        var interval = window.setInterval( ()=>{
            console.log( "Try render" );
            if ( w2.closed ) {
                window.clearInterval( interval );
                return;
            }

            let place = w2.document.getElementById( "printable" );
            if ( place == null ) {
                if ( Date.now() - startTime > 10000 )
                    window.clearInterval( interval );
                return;
            }
            
            render(
                //this.renderForPrint( params ),
                <PageComponent page={this} />,
                place
            );
            window.clearInterval( interval );
            console.log( "Render success" );
        }, 100 );
    }

    // /** @protected */
    // renderForPrint( params? ) {
    //     return <PrintComponent key={this.pageUid} page={this} state={this.getPrintState()} />;
    // }

    /** @protected */
    getPrintState() {
        let layout = this.formInfo?.printLayout || this.props.printLayout;

        if ( !layout )
            return "Invalid form";

        return this.prepareLayoutElem( layout );
    }

    /** Обработка нажатия на Enter */
    onPressEnter() {
        if ( this.isDialog() && !this.isEditMode() ) {
            this.close();
        }
    }

    /** Получить описание поля по имени */
    // getField( fieldName ) {
    //     if ( this.typeDesc != null ) {
    //         let f;
    //         f = this.typeDesc.getField( fieldName );
    //         if ( f ) return f;
    //     }
        
    //     if ( this.fields )
    //         return this.fields.find( f => f.name === fieldName );
    // }

    notify() {
        this.pageEvt.notify();
        this.titleEvt.notify();
    }

    getState() {
        let layout = this.formInfo?.layout || this.props.layout;

        if ( !layout )
            return {
                type: "Label",
                value: "Invalid form"
            };

        return this.prepareLayoutElem( layout );
    }

    /** Формирования разметки */
    prepareLayoutElem( layout: FormLayout ) {
        let state : any = { ...layout };
        if ( layout.visible != null ) {
            state._visible = this.matchCondition( layout.visible );
            //this.evalScript( layout.visible, null, false );
        }

        let elements_all : any[] = [ layout.top, layout.left, layout.center, layout.elements, layout.rows, layout.columns, layout.body, layout.right, layout.bottom ];
        let elements : FormLayout[] = elements_all.flat().filter( f => f != undefined );

        state._elements = elements.map( e => this.prepareLayoutElem( e ) );

        if ( state.type === "Buttons" && this.allActions ) {
            let place = state.place || state.placeName;
            let actions = this.allActions.filter( a => a.place === place && this.matchCondition( a.visible ) );
            actions = actions.map( a => ({ ...a, onClick: ()=>this.startAction( a, undefined, false ) }) );
            state.actions = actions;
        }
        return state;
    }

    getDialogSize() : [ string, string ] {
        if ( this.props.dialogSize )
            return this.props.dialogSize;

        return [ "80%", "90%" ];
    }

    notifyPathChanged() {
        console.log( "Not implemented: page.notifyPathChanged")
    }
}

export const PageContext = createContext<Page>( null as any );