import { Application } from "../App";
import { Page } from "./Page";

declare var app: Application;

export abstract class BaseListPage extends Page {
    documents: any[] = [];
    selectMode: boolean = false;

    onRowClick( doc ) {
        if ( this.props.onRowClick ) {
            this.props.onRowClick( doc, this );
            return;
        }

        var options : any = {
            mode: "view",
            target: "dialog",
            docId: doc.id,
            path: doc.path,
            typeName: this.typeName,
        };

        app.showView( options );
    }

    setIdArray( idArray: any[] ) {
        // pass
    }

    startEdit() {}
    endEdit() {}
    
}
