import {ReactEvt, utils} from '../utils';
import { TableSource } from './TableSource';
import { FilterValue } from './FilterValue';
import { Application } from '../App';
import { BaseListPage } from './BaseListPage';
import { FormLayout } from '../Interfaces';

declare var app: Application;

export class DocListPage extends BaseListPage {
    // filter: any;
    wpath: string; // Где взять настройки ширины в userConf
    selectMode = false;
    singleSelect: boolean;
    tableSource: TableSource;
    tableEvt: ReactEvt;
    documents: any[] = [];
    filterHidden = false;
    filterValues: FilterValue[] = [];
    needReload = false;

    // For pagination
    curPage: number = 0;
    maxPage: number = 0;
    pageSize: number = 25;
    docsCount: number = 0;

    beforeDocumentsLoad?: (params: any) => void;

    constructor( props ) {
        if ( props.valueMode || (props.viewMode != null && props.viewMode != "select" ))
            throw Error( "Invalid DocListPage usage" );

        super( props );

        this.singleSelect = props.selectionMode != "multiple";

        this.tableEvt = new ReactEvt( ()=>this.tableSource );
        
        this.formName = props.formName || "ListForm";
        // this.filter = props.filter ? {...props.filter} : null;
        this.mode = props.mode || "list";

//        if ( !props.hideCommands )
//            this.state.commandsName = "listCommands";

        this.wpath = props.wpath || this.path;
        this.tableSource = new TableSource( this );
    }

    async init() {
        this.initType();

        if ( this.props.filterHidden || this.pathInfo?.desc.filterHidden )
            this.filterHidden = true;

        if ( !this.props.filter && this.pathInfo?.desc.defaultFilter )
            this.props.filter = this.pathInfo.desc.defaultFilter;

        await this.prepareFilter();

        try {
            await this.load( 0 );
            if ( this.viewMode === "select" )
                this.startSelect();
        } catch ( err: any ) {
            this.setError( err.message );
        }

        if ( typeof this.props.onReady === "function" )
            this.props.onReady( this );

        // reassgn table source
        this.tableSource = new TableSource( this );
        await this.tableSource.init();
        super.init();
    }

    getUrl() {
        return utils.makeUrl( { mode: this.mode, path: this.path, viewMode: this.viewMode } );
    }

    /** ИД для замены новой страницей */
    getReplaceKey() {
        if ( this.fixed || this.selectMode )
            return undefined;

        return this.getUrl();
    }
    
    startSelect() {
        if ( this.selectMode )
            return;

        this.fixed = true;

        this.selectMode = true;
        this.tableSource.startSelectMode();
        this.notify();
    }

    endSelect() {
        if ( !this.selectMode )
            return;

        this.selectMode = false;
        this.tableSource.endSelectMode();
        this.notify();
    }

    // For compatibility
    stopSelect() { 
        this.endSelect();
    }

    isEditMode(): boolean {
        return this.selectMode;
    }

    cancelEdit() {
        this.endSelect();
    }
    
    /*
    onEvent( event ) {
        switch( event.type ) {
            case "filterChange":
            {
                let {field,value} = event;
                this.state.filter[field.name] = value;
                //let filter = { ...this.state.filter, [field.name] : value };
                //this.setState( { filter } );
                break;
            }
                
            case "rowClick":
                this.onRowClick( event.params );
                break;
                
            default:
                super.onEvent( event );
        }
    }
    */

    async prepareFilter() {
        if ( this.filterHidden )
            return;

        this.filterValues = [];
        
        if ( this.pathInfo?.desc.filterFields ) {
            await this._parseFilterFields( this.pathInfo.desc.filterFields );
        } else {
            let ff = this.typeInfo?.getAttr( "filterFields" );
            if ( ff )
                await this._parseFilterFields( ff );
        }
    }

    private async _parseFilterFields( fields, isTop? ) {
        if ( isTop === undefined ) {
            if ( fields.top )
                this._parseFilterFields( fields.top, true );

            if ( fields.bottom )
                this._parseFilterFields( fields.bottom, false );

            if ( fields.top || fields.bottom )
                return;
        }

        if ( !Array.isArray( fields) )
            return;

        let flock = this.props.filterLock || [];

        for ( let fieldName of fields ) {
            if ( this.filterValues.findIndex( f => f.name === fieldName ) != -1 )
                continue;

            let field = this.typeInfo!.getField( fieldName );

            if ( !field ) {
                console.error( "Invalid filter: " + fieldName );
                continue;
            }

            field = { ...field };
            delete field.onChange;
            delete field.onBlur;
            delete field.onEnter;
            delete field.onFocus;

            let values = field.values;

            if ( field.valuesDict ) {
                if ( field.valuesDict == "Status" && this.pathInfo?.statusValues )
                    values = this.pathInfo.statusValues.map( d => [ d.id, d.label ] );
                else {
                    let dict = await app.requestDict( field.valuesDict );
                    if ( dict?.values )
                        values = dict.values.map( vv => [ vv.name, vv.label ] );
                }
            }

            if ( values ) {
                // if ( !field.noKeys )
                //     values = values.map( ([k,v]) => [ k, k + " - " + v ] );

                if ( values[0] && values[0][0] )
                    values = [ ["", ""], ...values ];

                field.comboValues = values.map( ([k,v]) => (k == null || k === "") ? [k, v ] : [ k, k + " - " + v ] );
            }

            let ro = flock.indexOf( fieldName ) != -1;
            let fv = new FilterValue( field, ro, this );
            fv.isTop = isTop;

            let v = this.props.filter?.[fieldName];
            if ( v != null ) {
                if ( typeof v === "string" )
                    fv.assign( { oper: "=", text: v } );
                else
                   fv.assign( v );
            }

            this.filterValues.push( fv );
        }
    }

    getFilter() : any {
        if ( this.filterHidden )
            return this.props.filter;

        let filter: any = {};
        for ( let fv of this.filterValues ) {
            if ( fv.isSet() )
                filter[ fv.name ] = fv.getFilterValue();
        }
        return filter;
    }

    clearFilter() {
        let clearOne;
        if ( this.props.filter ) {
            let flt = this.props.filter;
            clearOne = f => f.assign( flt[f.name] );
        } else {
            clearOne = f => f.clear();
        }

        this.filterValues.forEach( clearOne );
    }
    
    sortByOrder( docs, orders ) {
        if ( orders.length == 0 )
            return docs;

        function mkCmp( prev, field, desc ) {
            return function( a, b ) {
                let res;
                if ( prev != null ) {
                    res = prev(a,b);
                    if ( res != 0 )
                        return res;
                }

                if ( a[field] < b[field] )
                    res = -1;
                else if ( a[field] > b[field] )
                    res = 1;
                else
                    res = 0;
                 
                return desc ? -res : res;
            };
        }

        var cmp: any = null;
        for ( let o of orders ) {
            cmp = mkCmp( cmp, o.field, o.desc );
        }

        docs.sort( cmp );
    }
    
    onDocumentsLoaded_OLD( docs ) {
        // let sorted = flags && flags.sorted;
        // let orders = (this.pathInfo && this.pathInfo.orderBy) || (this.typeDesc && this.typeDesc.orderBy);
        // if ( !sorted && orders ) {
        //     // Сортируем что получили
        //     this.sortByOrder( docs, orders );
        // }
        
        this.documents = docs;

        if ( this.typeInfo?.desc.onList ) {
            this.evalScript( this.typeInfo.desc.onList );
        }

        this.notify();
    }
    
    onFieldSubaction( field, value, doc, evt ) {
        if ( evt.originalEvent )
            evt.originalEvent.stopPropagation();
        else if ( evt.stopPropagation )
            evt.stopPropagation();

        this.evalScriptAsync( field.gridSubaction, { field, value, doc }, true );
    }
    
    async load( wantPage?: number ) {
        this.clearError();
        
        this.needReload = false;

        let filter = this.getFilter();
        
        let flags : any = {}; // { noSort: this.filterChanged };

        if ( wantPage === undefined ) {
            wantPage = this.curPage;
        }

        if ( wantPage ) {
            flags.pageNumber = wantPage;
            flags.pageSize = this.pageSize;
        }

        if ( this.beforeDocumentsLoad ) {
            this.beforeDocumentsLoad( { filter, flags } );
        }
        
        this.incLoading();

        try {
            let res;
            try {
                let res = await app.docSvc.getDocList( { filter, path: this.path, flags } );
                this.onDocumentsLoaded( res.documents, res.flags, wantPage, flags.pageSize, filter );
            } catch ( err : any ) {
                this.setError( "Ошибка загрузки даннных" );
                app.showError( err.message );
            }

            // TODO: need hide actions ?

            if ( res?.actions && Array.isArray( res.actions ) )
                this.allActions = res.actions;
            else
                await this.requestActions();
        } finally {
            this.decLoading();
        }
    }

    onDocumentsLoaded( documents, flags, pageNumber, pageSize, filter ) {
        this.curPage = pageNumber;

        let isAll;
        if ( pageNumber != null )
            isAll = documents.length < pageSize;
        else
            isAll = documents.length < 500;

        if ( isAll ) {
            let add = pageNumber ? (pageNumber - 1) * pageSize : 0;
            this.setDocsCount( documents.length + add );
        } else {
            app.docSvc.getCount( { filter, path: this.path } ).then( 
                res => this.setDocsCount( res.count ) ).catch( 
                err => { this.showError( err.message ); this.setDocsCount( null ) } );
        }

        this.documents = documents;

        if ( this.typeInfo?.desc.onList ) {
            this.evalScript( this.typeInfo.desc.onList );
        }

        this.notify();
    }

    setDocsCount( count ) {
        this.docsCount = count;
        if ( count == null )
            this.maxPage = 0;
        else
            this.maxPage = Math.max( Math.ceil( count / ( this.pageSize ) ), 1 );
    }

    //filterChanged( name, value ) {
    //    filter = this.state.filter;
    //    
    //    if ( value === "" )
    //        delete filter[name];
    //    else
    //        filter[name] = value;
    //}

    getDefaultLayout() {
        return {
            type: "VBox",
            top: {
                type: "Buttons",
                placeName: "listCommands"
            },
            bottom: {
                type: "DocList"
            }
        };
    }

    /*
    executeAction( action, params ) {
        switch ( action.actionType ) {
            case "createNewDoc":
            {
                let subform = new CreateDocumentPage( this.typeName, false );

                if ( this.dictInfo ) {
                    subform.setDictInfo( this.dictInfo );
                }

                subform.load();
                this.addDialog( subform );
                break;
            }

            case "showOtherDocInDialog":
            {
                let subform = new ShowDocumentPage( action.docTypeName, action.docId, true );
                this.addDialog( subform );
                break;
            }

            case "createDocInDialog":
            {
                let subform = new CreateDocumentPage( this.typeName, false );
                subform.setDocData( { type: this.typeName } );
                subform.startEdit();
                subform.save = () => { this.onAdd( subform.collectUpdates() ); this.closeDialog( subform ); };
                this.addDialog( subform );
                break;
            }

            default:
                super.executeAction( action, params );
        }
    }*/

    onRowClick( doc ) {
        if ( this.props.onRowClick ) {
            this.props.onRowClick( doc.id );
            return;
        }

        app.showView( { mode: "show", docId: doc.id, docName: doc.name, path: doc.path || this.path }, 
                       false, this.isSubform() || this.isDialog() );
    }
    
    allowOk() {
        switch ( this.viewMode ) {
        case "select":
        case "editDocs":
            return true;
        default:
            return false;
        }
    }

    
    // showAddDocs() {
    //     if ( this.valueMode === "ID_ARRAY" ) {
    //         let opt = {
    //             mode: "list",
    //             target: "dialog", 
    //             path: this.path,
    //             typeName: this.typeName,
    //             viewMode: "select",
    //             onOk: (docs, page) => {
    //                 if ( docs )
    //                     this.doAddDocsId( docs.map( d=>d.id ) );
    //                 page.close();
    //             }
    //         };
    //         window.app.showView( opt );
    //     } else if ( this.valueMode === "INLINE_ARRAY" ) {
    //         let opt = {
    //             mode: "show",
    //             target: "dialog", 
    //             path: this.path,
    //             typeName: this.typeName,
    //             viewMode: "create",
    //             valueMode: "INLINE",
    //             docData: {},
    //             onOk: (doc, page) => {
    //                 this.doAddDoc( doc );
    //                 page.close();
    //             }
    //         };
    //         window.app.showView( opt );
    //     }
    // }
    
    onOk() {
        let sel = this.tableSource.getSelectedDocs();
        if ( this.props.onOk )
            this.props.onOk( sel, this );
        else
            this.close();
    }
    
    setNeedReload() {
        if ( app.currentPage === this )
            this.load();
        else
            this.needReload = true;
    }
    
    onShowPage() {
        if ( this.needReload )
            this.load();
    }

    getSelectedDoc() {
        let docs = this.tableSource.getSelectedDocs();
        if ( !docs || docs.length == 0 ) {
            app.showWarning( "Не выделена строка" );
            return;
        }

        if ( docs.length > 1 ) {
            app.showWarning( "Выделено более одной строки" );
            return;            
        }

        return docs[0];
    }

    getSelectedDocs() {
        return this.tableSource.getSelectedDocs();
    }

    getSelectedIds() {
        if ( !this.tableSource.hasSelection() )
            return;

        return this.tableSource.getSelectedIds();
    }

    // showSelectedDoc() {
    //     var doc = this.getSelectedDoc();

    //     if ( doc ) {
    //         if ( this.valueMode === "INLINE_ARRAY" )
    //             window.app.showView( { target: "dialog", mode: "show", docData: doc, typeName: this.typeName, valueMode: "INLINE" } );
    //         else
    //             window.app.showView( { target: "dialog", mode: "show", docId: doc.id, docName: doc.name, path: doc.path || this.path } );
    //     }
    // }

    // editSelectedDoc() {
    //     if ( this.valueMode !== "INLINE_ARRAY" ) {
    //         // Сюда не должны по-хорошему попасть
    //         return;
    //     }

    //     var doc = this.getSelectedDoc();
    //     if ( doc == null )
    //         return;
            
    //     var onOk = (newDoc, page) => {
    //         this.onDocumentsLoaded( this.documents.map( d => (d===doc) ? newDoc : d ) );
    //         page.close();

    //         if ( this.props.onChange )
    //             this.props.onChange( this.documents );
    //     };

    //     window.app.showView( { target: "dialog", mode: "show", docData: doc, typeName: this.typeName, valueMode: "INLINE", viewMode: "edit", onOk: onOk } );
    // }
    
    //  getPrintState() {
    //     let res = { 
    //         ...this.state,
    //         print: true,
    //         layout: this.formInfo && this.formInfo.printLayout,
    //         filter: null
    //     };

    //     return res;
    // }

    // renderForPrint( params ) {
    //     if ( params === "allDocs" ) {
    //         let res = [];
    //         let docs = this.isEditMode() ? this.state.selection.map( d=>d._doc ) : this.documents;
    //         if ( docs.length == 0 ) {
    //             return <div>Нет документов для печати</div>;
    //         }

    //         let last = docs[ docs.length - 1 ];
    //         this.printPages = [];
    //         for ( let doc of docs ) {
    //             let desc = { valueMode: "INLINE", docData: doc, target: "subform", path: this.path, typeName: doc.type };
    //             if ( this.pathInfo && this.pathInfo.reloadBeforePrint ) {
    //                 desc.valueMode = "ID";
    //                 desc.docId = doc.id;
    //                 desc.docData = null;
    //             }

    //             let pg = new ViewDocPage( desc );
    //             this.printPages.push( pg );
    //             res.push( <div style={{pageBreakAfter: doc === last ? null : "always"}} key={res.length} >
    //                 {pg.renderForPrint()}
    //             </div> );
    //         }
    //         return <div>{res}</div>;
    //     } else {
    //         return super.renderForPrint();
    //     }
    // }

    turnPagination() {
        if ( this.curPage == 0 ) {
            this.curPage = 1;
            this.pageSize = 25;
            this.load( 1 );
        } else {
            this.curPage = 0;
            this.maxPage = 0;
            //this.docsCount = null;
            this.maxPage = 0;
            this.load( 0 );
        }
    }

    movePg( no ) {
        if ( this.curPage == null ) return;

        let wantPage = Math.max( this.curPage + no, 1 );
        if ( this.maxPage != null ) {
            wantPage = Math.min( this.maxPage, wantPage );
        }
        this.load( wantPage );
    }

    prepareLayoutElem( layout: FormLayout ) {
        let state = super.prepareLayoutElem( layout );
        if ( state.type === "DocList" ) {
            state.tableSource = this.tableSource;
        }
        return state;
    }

    getDialogSize() : [ string, string ] {
        if ( this.props.dialogSize )
            return this.props.dialogSize;

        return [ "95%", "95%" ];
    }
}

// class PageTableSource extends TableSource {
//     page: DocListPage;
    
//     constructor( page: DocListPage ) {
//         super();
//         this.page = page;
//         this.typeName = page.typeName;
//         this.singleSelect = page.singleSelect;
//     }

//     notify() {
//         this.page.tableEvt.notify();
//     }

//     getDocsSrc(): any[] {
//         return this.page.documents;
//     }
// }
