import { Checkbox, Icon } from "@blueprintjs/core";
import { Application } from "../App";
import { FieldInfo, TypeInfo } from "../TypeInfo";
import { FieldValue } from "./DocSource";
import { Page } from "./Page";
import { utils } from "../utils";
import { BaseListPage } from "./BaseListPage";

declare var app: Application;

interface SelectedIndex {
    [key: string]: boolean;
}

interface OneWidth {
    fix_w: number;
    scale_w: number;
}

interface WidthInfo {
    fixed: number;
    variable: number,
    // widths: OneWidth[]
    widths: number[]
}

// export interface BaseListPage {
//     props: any;
//     documents: any[];
//     typeName: string;

//     notify() : void;
//     onRowClick( doc ) : void;
// }

export class TableSource {
    page: BaseListPage;
//    field: FieldInfo;
//    value: FieldValue;

    docs?: any[];
    isFiles: boolean = false;
    typeName!: string;
    typeInfo!: TypeInfo;
    gridFields!: FieldInfo[];
    selectMode = false;
    lastSelect = false;
    lastSelectId?: any;
    lastValue?: any;
    selected?: SelectedIndex;
    singleSelect = false;
    winfo?: WidthInfo;
    docToRow?: (doc:any)=>any;
    
    constructor( page: BaseListPage ) {
        this.page = page;
        this.isFiles = page.props.isFiles;
        // this.init();
    }

    async init() {
        this.typeName = this.page.typeName;

        let ti = this.typeInfo = app.getType( this.typeName );
        if ( !ti ) {
            // app.showError( `Unknown type: ${this.typeName}` );
            console.error( "Bad page typeName: ", this.page );
            return;
        }
        if ( !ti.desc.gridFields ) {
            app.showError( `No gridFields in type: ${this.typeName}` );
            return;
        }

        for ( let ff of ti.desc.gridFields ) {
            if ( !ti.getField( ff ) ) console.log( "No field: ", ff );
        }

        this.gridFields = ti.desc.gridFields.map( f => ti.getField( f ) ).filter( f => f );
        await this.prepareDocToRow();
        this.prepareWidths();
        if ( this.page.selectMode )
            this.startSelectMode();
        else
            this.notify();
    }

    isEditMode() : boolean {
        return false;
    }

    notify() : void {
        this.page.notify();
    }

    getFields() {
        return this.gridFields;
    }

    getDocsSrc() : any[] {
        return this.page.documents;
    }

    getRows() {
        let value = this.getDocsSrc();
        if ( value === this.lastValue )
            return this.docs;

        if ( value == null || value.length === 0 || !this.docToRow ) {
            return [];
        }

        if ( !Array.isArray( value ) ) {
            if ( typeof value === "object" )
                value = [ value ];
            else {
                console.warn( "Not an Array:", value );
                return [];
            }
        }

        let docs = value.map( this.docToRow );
        this.docs = docs;
        this.lastValue = value;
        return docs;
    }

    async prepareDocToRow() {
        for ( let field of this.gridFields ) {
            if ( (field.type === "string" || field.type === undefined) ) {
                if ( field.values ) {
                    field.valuesIndex = Object.fromEntries( field.values );
                } else if ( field.valuesDict ) {
                    let vv = await app.getDictValues( field.valuesDict );
                    if ( vv ) {
                        field.valuesIndex = {};
                        for ( let v of vv ) {
                            field.valuesIndex[v.id] = v.label;
                        }
                    }
                }
            }
        }

        let code: Function[] = [];
        for ( let field of this.gridFields ) {
            let name = field.name;
            if ( this.isFiles && name === "name" ) {
                code.push( doc => { 
                    let href = app.getDownloadUrl( doc.id );
                    doc.name = <a href={href} target="_blank" onClick={e=>e.stopPropagation()}>{doc.name} <Icon icon="download"/></a>;
                } );
                continue;
            }

            switch ( field.type ) {
                case "date":
                    code.push( doc => { 
                        doc[name] = utils.dateToText( doc[name] );
                    } );
                    break;

                case "datetime":
                    code.push( doc => { 
                        doc[name] = utils.datetimeToText( doc[name] );
                    } );
                    break;

                case "amount":
                    code.push( doc => { 
                        doc[name] = utils.amountToText( doc[name] );
                    } );
                    break;

                case "bool":
                case "boolean":
                    code.push( doc => { 
                        doc[name] = doc[name] ? "Да" : "Нет";
                    } );
                    break;

                case "string":
                case undefined:
                    if ( field.valuesIndex ) {
                        let values = field.valuesIndex;
                        switch( field.rowFormat ) {
                            case "label":
                                code.push( doc => {
                                    if ( doc[name] in values )
                                        doc[name] = values[ doc[name] ];
                                } );
                                break;

                            case "key_label":
                            case undefined:
                                    code.push( doc => {
                                    if ( doc[name] in values )
                                        doc[name] = doc[name] + " - " + values[ doc[name] ];
                                } );
                                break;
                        }
                    }
                    break;
            }
        }
        this.docToRow = doc => {
            let res = { ...doc, _doc: doc };
            for ( let func of code )
                func( res );
            return res;
        }
    }

    onRowClick( row ) {
        let doc = row._doc;
        if ( this.selectMode ) {
            if ( doc.hint ) 
                app.showSuccess( doc.hint, "Подсказка" );

            if ( doc.id == null ) {
                app.showError( "Нет поля id у объекта" );
                return;
            }
            this.lastSelect = true;
            this.lastSelectId = doc.id;
    
            this.selected = { [doc.id]: this.lastSelect };
            this.notify();
        } else {
            this.onClick( doc );
        }
    }

    // click default action
    onClick( doc ) {
        this.page.onRowClick( doc );
        // var options : any = {
        //     target: "dialog",
        //     docData: doc,
        //     typeName: this.typeName,
        //     valueMode: "INLINE"
        // };

        // app.showView( options );
    }

    onCtrlRowClick( doc ) {
        if ( !this.selectMode || !this.selected ) return;
        if ( doc.id == null ) {
            app.showError( "Нет поля id у объекта" );
            return;
        }

        if ( this.singleSelect ) {
            this.selected = { [doc.id]: this.lastSelect };
            this.notify();
            return;
        }

        this.lastSelect = !this.selected[ doc.id ];
        this.lastSelectId = doc.id;

        this.selected[ doc.id ] = !this.selected[ doc.id ];
        this.notify();
    }

    onShiftRowClick( doc ) {
        if ( !this.selectMode || !this.selected ) return;

        if ( doc.id == null ) {
            app.showError( "Нет поля id у объекта" );
            return;
        }

        if ( this.singleSelect ) {
            this.selected = { [doc.id]: this.lastSelect };
            this.notify();
            return;
        }

        if ( !this.lastSelectId || this.lastSelectId === doc.id ) {
            this.lastSelect = true;
            this.lastSelectId = doc.id;
    
            this.selected = { [doc.id]: this.lastSelect };
            this.notify();
            return;
        }

        // let docs = this.getDocsSrc();
        let pos1 = this.docs!.findIndex( d => d.id === doc.id );
        let pos2 = this.docs!.findIndex( d => d.id === this.lastSelectId );
        if ( pos1 < 0 || pos2 < 0 ) {
            console.warn( "get doc positions error" );
            return;
        }

        let start = Math.min( pos1, pos2 );
        let end = Math.max( pos1, pos2 );
        for ( let i = start; i <= end; ++i ) {
            let d = this.docs![i];
            this.selected[ d.id ] = this.lastSelect;
        }
        this.notify();
    }

    prepareWidths() {
        if ( !this.gridFields ) return;

        let total = 0;
        let widths: number[] = [];
        for ( let field of this.gridFields ) {
            let fix_w = Number( field.minColumnWidth || 20 );
            let scale_w = field.scaleColumnWidth == null ? 100 : Number( field.scaleColumnWidth );
            widths.push( fix_w + scale_w );
            total += fix_w + scale_w;
        }

        this.winfo = {
            fixed: 0,
            variable: total,
            widths: widths.map( w => w / total )
        }
        // console.log( "WInfo", this.winfo );
    }

    getWidths() {
        if ( !this.winfo ) {
            this.prepareWidths();
            if ( !this.winfo ) return null;
        }

        return this.winfo.widths;
    }

    recalcWinfo() {
        if ( !this.winfo ) return;

        // let fixed = 0;
        // let variable = 0;
        // for ( let {fix_w, scale_w} of this.winfo.widths ) {
        //     fixed += fix_w;
        //     variable += scale_w;
        // }
        // this.winfo.fixed = fixed;
        // this.winfo.variable = variable;
    }

    onColumnResize( no, colWidth, width ) {
        // console.log( "onColumnResize: ", no, colWidth );
        let newWidths = [...this.winfo!.widths];
        if ( newWidths.length < 2 )
            return;

        let prevWidth = newWidths[no];
        colWidth = newWidths[no] = Math.max( 0.01, colWidth );
        if ( no < newWidths.length - 1 ) {
            let delta = colWidth - prevWidth;
            if ( newWidths[no + 1] > delta + 0.01 ) {
                newWidths[ no + 1 ] -= delta;
            } else {
                // decrease all rights
                let total = newWidths.reduce( (a,b) => a+b, 0 );
                let leftPart = newWidths.slice( 0, no + 1 ).reduce( (a,b) => a+b, 0 );
                let rightPart = total - leftPart;
                let scale = (1 - leftPart) / rightPart;
                for ( let i = no + 1; i < newWidths.length; ++i )
                    newWidths[i] *= scale;
            }
        } else { 
            // decrease all left
            let leftPart = newWidths.slice( 0, no ).reduce( (a,b) => a+b, 0 );
            let scale = (1 - colWidth) / leftPart;
            for ( let i = 0; i < no; ++i )
                newWidths[i] *= scale;
        }

        this.winfo = {
            ...this.winfo!,
            widths: newWidths
        };
        this.notify();
    }

    startSelectMode() {
        this.selectMode = true;
        this.selected = {};
        this.lastSelect = true;
        this.lastSelectId = undefined;
        this.notify();
    }

    // onDeleteAll() {
    //     this.setDocsSrc( [] );
    // }

    endSelectMode() {
        this.selectMode = false;
        this.selected = undefined;
        this.notify();
    }

    getSelectedDocs() {
        if ( !this.hasSelection() )
            return [];

        let docs = this.getDocsSrc();
        return docs.filter( d => this.selected![d.id] );
    }

    getSelectedIds() {
        if ( !this.hasSelection() )
            return [];

        let res = Object.entries( this.selected! );
        return res.filter( item => item[1] ).map( item => item[0] );
    }

    getSelectedCount() {
        return this.getSelectedIds().length;
    }

    hasSelection() {
        if ( !this.selected ) return false;

        return Object.values( this.selected ).filter( v => v ).length > 0;
    }

    isEmpty() {
        let value = this.getDocsSrc();
        return ( value == null || value.length === 0 || !Array.isArray( value ));
    }
}

// export class InlineTableSource extends TableSource {
//     field: FieldInfo;
//     value: FieldValue;

//     constructor( value: FieldValue) {
//         super();
//         this.value = value;
//         this.field = value.fieldInfo;
//         this.isFiles = this.field.type === "files";
//         this.typeName = value.fieldInfo.typeName!;
//         this.init();
//     }

//     isEditMode() : boolean {
//         return this.value.editMode;
//     }

//     notify(): void {
//         return this.value.revt.notify();
//     }

//     getDocsSrc() {
//         return this.value.getValue();
//     }

//     onAdd() {
//         if ( this.isFiles ) {
//             let options = {
//                 mode: "files",
//                 // parentPage: this.model,
//                 target: "dialog",
//                 fieldValue: this.value
//             };

//             app.showView( options );
//             return;
//         }

//         if ( this.field.addFromPath ) {
//             let options : any = {
//                 mode: "doc_select",
//                 target: "dialog",
//                 // selectedValue: value,
//                 path: this.field.addFromPath,
//                 onOk: res => this.doAdd( res )
//             }

//             if ( this.field.addFilterScript ) {
//                 options.onReady = pg => {
//                     pg.evalScript( this.field.addFilterScript, { options, field: this.field } );
//                 };
//             }

//             app.showView( options );
//             return;
//         }

//         super.onAdd();
//     }

//     setDocsSrc( docs: any[] ) : void {
//         this.value.setInputValue( docs );
//     }

// }