
import { Application } from "./App";

declare var app: Application;

export class Service {
    debug: boolean;
    url?: string;

    static id = 1;
    sessionId?: string;

    procUrl!: string;

    constructor() {
        this.debug = window.location.host.startsWith("localhost");
    }

    getURL() : string {
        if ( app.sessionId !== this.sessionId || !this.url )
            this.url = this.procUrl + "?session=" + app.sessionId;

        return this.url;
    }

    private _newId() {
        return ++Service.id;
    }

    getBaseUrl() {
        if (window.location.host === "localhost:3000")
            //return "http://srv2.idft.ru/DocHub-web/";
            return "http://localhost:8080/";

        return "";
    }

    async sendJson(method, params) {
        let newId = this._newId();

        this.beforeJson(params);
        var req = {
            jsonrpc: "2.0",
            method: method,
            params: params,
            id: newId
        };

        let options : any = {
            method: "POST",
            credentials: "include",
            mode: "cors",
            //crossDomain: true,
            headers: { "Content-Type": "text/plain" },
            body: JSON.stringify(req),
            // xhrFields: {
            //     withCredentials: true
            // }
        };

        let response = await fetch( this.getURL(), options );
        if ( response.status == 200 ) {
            let res = await response.json();
            if ( res.error )
                throw new Error( res.error.message );

            if (res.id !== req.id)
                throw new Error("Invaild reply ID");

            return res.result;
        }

        throw new Error("Ajax error");
    }

    beforeJson(obj) {
        if (obj != null && typeof obj === "object") {
            var i;
            for (i in obj) {
                if (obj[i] === undefined)
                    obj[i] = null;
                else
                    this.beforeJson(obj[i]);
            }
        }
    }
};

export class DocService extends Service {
    constructor() {
        super();
        this.procUrl = this.getBaseUrl() + "DocHubService";
    }

    getDocList(params) {
        return this.sendJson("getDocList", params);
    }

    getCount(params) {
        return this.sendJson("getCount", params);
    }

    getDocData(params) {
        return this.sendJson("getDocData", params);
    }

    //        this.dispatch( { type: Actions.DOC_LIST_READY, formId, requestId, documents: res.documents } );
    //        this.dispatch( { type: Actions.DOC_LIST_ERROR, formId, requestId, error } );
    //        this.dispatch( { type: Actions.DOC_DATA_READY, formId, docId, requestId, docData: res.docData, docActions: res.docActions } );
    //        this.dispatch( { type: Actions.DOC_DATA_ERROR, formId, docId, requestId, error } );
    //        this.dispatch( { type: Actions.TYPE_DESC_READY, formId, typeName, requestId, typeDesc: res.typeDesc } );
    //        this.dispatch( { type: Actions.TYPE_DESC_ERROR, formId, typeName, requestId, error } );

    getNewDoc(typeName, path) {
        return this.sendJson("getNewDoc", { typeName, path });
    }

    createDoc(params) {
        return this.sendJson("createDoc", params);
    }

    saveDoc(params) {
        return this.sendJson("saveDoc", params);
    }

    deleteDoc(params) {
        return this.sendJson("deleteDoc", params);
    }

    startAction(params) {
        return this.sendJson("startAction", params);
    }

    getActions(params) {
        return this.sendJson("getActions", params);
    }

    getMenu(params) {
        return this.sendJson("getMenu", params);
    }
};

export class AuthService extends Service {
    constructor() {
        super();
        this.procUrl = this.getBaseUrl() + "AuthService";
    }

    login(userName, password) {
        return this.sendJson("login", { userName, password });
    }

    logout() {
        return this.sendJson("logout", {});
    }

    getSessionInfo() {
        return this.sendJson("getSessionInfo", {});
    }

    getUserProfile(userName) {
        return this.sendJson("getUserProfile", { userName });
    }
};

export class FilesService extends Service {
    constructor() {
        super();
        this.procUrl = this.getBaseUrl() + "FilesService";
    }

    async sendFiles(params) {
        var fdata = new FormData();
        for (var key in params) {
            fdata.append(key, params[key]);
        }

        let options : any = {
            method: "POST",
            mode: "cors",
            credentials: "include",
            // headers: { "Content-Type": "text/plain" },
            body: fdata
        };

        let response = await fetch( this.getURL(), options );
        if ( response.status == 200 ) {
            let res = await response.json();
            if ( res.error )
                throw new Error( res.error.message );

            return res.result;
        }

        throw new Error("Ajax error");
    }
}
    
export class RestService extends Service {
    constructor() {
        super();
        this.procUrl = this.getBaseUrl() + "rest";
    }

    async get(url) {
        let options : any = {
            method: "GET",
            mode: "cors"
        };

        let fullUrl = this.procUrl + url + "?session=" + app.sessionId;

        let response = await fetch( fullUrl, options );
        if ( response.status == 200 ) {
            return await response.json();
        }

        throw new Error("Ajax error");
    }

    async post(url, data) {

        let options : any = {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        };

        let fullUrl = this.procUrl + url + "?session=" + app.sessionId;

        let response = await fetch( fullUrl, options );
        if ( response.status == 200 ) {
            return await response.json();
        }

        throw new Error("Ajax error");
    }
}
