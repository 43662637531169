import { TypeInfo } from "./TypeInfo";
import { Application } from "./App";

declare var app: Application;

export class PathInfo {
    desc: any;
    name: string;
    typeName: string;
    typeInfo?: TypeInfo;
    module: any;
    statusValues?: any[];

    prepared = false;
    _preparing = false;

    afterRead?: (x:any)=>void;

    get readonly() : boolean {
        return this.desc.readonly;
    }

    constructor( desc ) {
        this.desc = desc;
        this.name = desc.id;
        this.typeName = desc.typeName;
    }

    async prepare() {
        if ( this.prepared )
            return;

        if ( this._preparing )
            throw Error( "TypeInfo prepare loop: " + this.name );

        this._preparing = true;

        if (this.desc.typeName) { 
            this.typeName = this.desc.typeName;
            this.typeInfo = await app.getType( this.typeName );
        }

        if ( this.typeInfo == null )
            console.warn( `Unknown type ${this.typeName} for path ${this.name}` );

        if ( this.desc.hasWorkflow )
            await this.prepareStatusValues();

        // TODO
        // if ( this.desc.clientModule ) {
        //     this.module = await app.getModule( this.desc.clientModule );
        //     if ( this.module.initPath ) {
        //         await this.module.initPath( this );
        //     }
        // }

        this._preparing = false;
    }

    private async prepareStatusValues() {
        let wfs : string[] = [];
        if ( this.desc.workflow )
            wfs.push( this.desc.workflow );

        if ( this.desc.workflows )
            wfs = [ ...wfs, ...this.desc.workflows ];

        let filter;
        if ( wfs.length == 0 )
            return;

        if ( wfs.length == 1 ) {
            filter = { workflow: { oper: "=", text: wfs[0] } };
        } else {
            filter = { workflow: { oper: "in", values: wfs } };
        }

        try {
            let res = await app.docSvc.getDocList( { path: "/dict/Status", filter } );
            this.statusValues = res.documents.filter( doc => !doc.id.startsWith( "-" ) && doc.label );
        } catch ( err ) {
            console.error( err );
        }
    }

    // async newDocId() {
    //     return await app.docSvc.
    // }

    async getDocument( docId, flags ) {
        let res = await app.docSvc.getDocData( { path: this.name, docId, flags } );
        let doc = res.docData;

        if ( doc && this.afterRead ) {
            let params = { doc, flags, pathInfo: this };
            await this.afterRead( params );
            return params.doc;
        }
        return doc;
    }

    async createDocument( doc, flags ) {
        return app.docSvc.createDoc( { path: this.name, doc, flags } );
    }

    async updateDocument( docId, updates, flags ) {
        return app.docSvc.saveDoc( { path: this.name, docId, updates, flags } );
    }

    async deleteDocument( docId, flags ) {
        return app.docSvc.deleteDoc( { path: this.name, docId, flags } );
    }

    async getDocList( filter, flags ) {
        let res = await app.docSvc.getDocList( { path: this.name, filter, flags } );
        return res.documents;
    }

    async getCount( filter ) {
        return app.docSvc.getCount( { path: this.name, filter } );
    }
};
