import { Page } from './Page';
import { ViewDocPage } from './ViewDocPage';
import { Application } from '../App';
import { DIALOG_ACTIONS } from '../constants';
import { FilesDropArea } from '../elems/FilesDropArea';

declare var app: Application;

export class AddFilesPage extends Page {
    files: File[] = [];
    editMode = true;

    getState() {
        return {
            type: "VBox",
            _elements: [
                {
                    type: "Content",
                    content: this._render()
                },
                {
                    type: "Buttons",
                    place: "dialog",
                    className: "dh-dialog-buttons",
                    actions: [
                        {
                            id: "dialogOk",
                            onClick: ()=>this.onOk(),
                            icon: "tick",
                            label: "OK",
                            highlight: "success",
                        }
                    ]
                }
            ]
        }
    }

    private _render() {
        let filesText = ( this.files && this.files.length > 0 ) ?  this.files.map( f=>f.name ).join( ", " ) : "-";

        return <div className="dh-files-page">
            <div className="dh-files-body">
                <FilesDropArea addFiles={files=>this.addFiles(files)} />
                <h3 style={{flex: "0 0 auto"}}>Выбраны файлы: {filesText}</h3>
            </div>
        </div>;
    }

    addFiles( files ) {
        this.files.push( ...files );
        this.notify();
    }

    getDoc() : File[] {
        return this.files;
    }

    onOk() {
        this.props.onOk( this.files );
        this.close();
    }
}

export function createAddFilesPage( onOk: (files:File[])=>void ) : Page {
    return new AddFilesPage( {
        mode: "show",
        actions: DIALOG_ACTIONS,
        target: "dialog",
        title: "Добавить файлы",
        dialogSize: [ "60%", "60%" ],
        onOk
    } );
}
