import { CSSProperties, useCallback, useMemo, useRef, useState } from "react";
import { Page } from "../pages/Page";
import { Dialog, Icon } from "@blueprintjs/core";
import { PageComponent } from "./PageComponent";

export function DhDialog( { page } : { page: Page } ) {
    let [ width, setWidth ] = useState( 0 );
    let [ height, setHeight ] = useState( 0 );
//    let [ x, setX ] = useState();
//    let [ y, setY ] = useState();
    let [ moving, setMoving ] = useState( false );
//    let [ pointerId, setPointerId ] = useState();
    let resizer = useRef<HTMLElement>( null );

    let dialogStyle : CSSProperties = useMemo( () => {
        let style: any = { 
            position: "absolute"
        };

        // let [w,h] = useMemo( ()=>page.getDialogSize(), [page] );

        if ( width == 0 ) {
            let [w,h] = page.getDialogSize();
            style.width = w;
            style.height = h;
        } else {
            style.width = width + "px";
            style.height = height + "px";
        }
        return style;
    }, [ width, height ] ); // , page.ready ] );

    let title = page.titleEvt.use();

    let handleClose = useCallback( ()=>page.close(), [page] );
    let handleClosed = useCallback( ()=>page.afterClose(), [page] );

    let onPointerDown = useCallback( evt => {
        if ( moving ) return;
        console.log( "before capture: ", resizer.current );
        resizer.current!.setPointerCapture( evt.pointerId );
//        setPointerId( evt.pointerId );
        setMoving( true );
        evt.preventDefault();
    }, [ moving, resizer, setMoving ] );

    let onPointerUp = useCallback( evt => {
        if ( !moving ) return;
        console.log( "before release: ", resizer.current );
        resizer.current!.releasePointerCapture( evt.pointerId );
        setMoving( false );
        evt.preventDefault();
    }, [ moving, resizer, setMoving ] );

    let onPointerMove = useCallback( evt => {
        if ( !moving ) return;
        console.log( "On move" );
        let sx = window.innerWidth;
        let sy = window.innerHeight;
        let sizeX = Math.min( Math.max( 2 * evt.clientX + 32 - sx, 200 ), sx );
        let sizeY = Math.min( Math.max( 2 * evt.clientY + 16 - sy, 200 ), sy );
        setWidth( sizeX );
        setHeight( sizeY );
        evt.preventDefault();
    }, [ setWidth, setHeight, moving ] );

    // console.log( "onPointerDown: ", typeof onPointerDown );

    return <Dialog isOpen={true} usePortal={false} enforceFocus={true} 
        style={dialogStyle}
        title={title}
        canEscapeKeyClose={page.isEasyClose()}
        canOutsideClickClose={page.isEasyClose()}
        onClose={handleClose}
        onClosed={handleClosed}>
            <div className="dh-dialog-body">
                <PageComponent page={page} />
            </div>
            <div className="dh-dialog-resizer" onPointerDown={onPointerDown} 
                    onPointerMove={onPointerMove} 
                    onPointerUp={onPointerUp} 
                    ref={resizer as any} >
                <Icon icon="arrow-bottom-right" />
            </div>
    </Dialog>;
}