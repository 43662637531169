import React, {useCallback, useContext, useState} from 'react';
import Hotkeys from 'react-hot-keys/esm/index.js';
import { Button, Collapse, ResizeSensor, Tooltip } from '@blueprintjs/core';
import { DocListPage } from '../pages/DocListPage';
import { PageContext } from '../pages/Page';
import { FilterElem } from './Filter';

export function TableFilter() {
    let page = useContext(PageContext) as DocListPage;
    let state = page.pageEvt.use();
    let [expanded,setExpanded] = useState( page.props.expandFilter );

    let onKeyDown = useCallback( (keyName, e, handle) => {
        switch(keyName) {
            case "shift+right": setExpanded( !expanded ); break;
            case "shift+delete": page.clearFilter(); break;
        }
    }, [expanded,setExpanded,page] );

    if ( page.filterHidden )
        return null;

    let fvis = page.filterValues.length > 0;

    let pagination: any = null;
    if ( page.curPage ) {
        pagination = <span>
            <Tooltip content="Предыдущая страница">
                <Button icon="chevron-left" onClick={()=>page.movePg(-1)} style={{width:"30px", height:"26px", marginLeft: "15px"}} />
            </Tooltip>    
            <Tooltip content="Назад на 10 страниц">
                <Button icon="double-chevron-left" onClick={()=>page.movePg(-10)} style={{width:"30px", height:"26px", marginLeft: "5px"}} />
            </Tooltip>
            <span className="dh-negative">Страница {page.curPage} из {page.maxPage || '?'}</span>
            <Tooltip content="Вперед на 10 страниц">
                <Button icon="double-chevron-right" onClick={()=>page.movePg(10)} style={{width:"30px", height:"26px", marginLeft: "5px"}} />
            </Tooltip>
            <Tooltip content="Следующая страница">
                <Button icon="chevron-right" onClick={()=>page.movePg(1)} style={{width:"30px", height:"26px", marginLeft: "5px"}} />
            </Tooltip>
        </span>;
    }

    let selection: any = null;
    if ( page.selectMode ) {
        selection = <span className="dh-negative" style={{marginLeft: "15px"}}>Выделено: {page.tableSource.getSelectedCount()} </span>
    }

    let loadedDocs: any = null;
    if ( !page.curPage && page.docsCount != page.documents.length ) {
        loadedDocs = <span className="dh-negative" style={{marginLeft: "15px"}}>В таблице: {page.documents.length} </span>
    }
              
    return <div className="filter">
        <Hotkeys 
        keyName="shift+delete, shift+right, shift+up, shift+down"
        onKeyDown={onKeyDown} />
            <div style={{flex: "0 0 auto"}} className="dh-buttons">
                { fvis && <Button icon="filter" rightIcon={ expanded ? "caret-up" : "caret-down"} onClick={()=>setExpanded(!expanded)}/> }
                <Button icon="refresh" text="Запросить" onClick={()=>page.load()} style={{width:"auto", marginLeft: "5px"}} />
                { fvis && <Tooltip content="Очистить фильтр">
                    <Button icon="eraser" onClick={()=>page.clearFilter()} style={{width:"30px", height:"26px", marginLeft: "5px"}} />
                </Tooltip> }
                <Tooltip content="Постраничный вывод">
                    <Button icon="multi-select" onClick={()=>page.turnPagination()} style={{width:"30px", height:"26px", marginLeft: "15px"}} />
                </Tooltip>
                {pagination}
                <span className="dh-negative" style={{marginLeft: "15px"}}>Всего документов: {page.docsCount || '?'}</span>
                {loadedDocs}
                {selection}
            </div>
            { fvis && <Collapse isOpen={expanded} keepChildrenMounted={true}>
                <div className="dh-collapse-body">
                    <FilterForm/>
                </div>
            </Collapse>
            }
        </div>;
}

export function FilterForm() {
    let page = useContext(PageContext) as DocListPage;
    let state = page.pageEvt.use();

    let items = page.filterValues;
    // let n = 0;
    // let items = page.filterValues.map( fv => {
    //     // let visible = expanded || fv.isTop;
    //     // // let className = (expanded || fv.top) ? "filterItem" : "hidden";
    //     // if ( visible )
    //     //     n++;
    //     return <FilterElem fv={fv} visible={true} key={fv.name}/>;
    //     // return <FilterElem fv={fv} visible={visible} key={fv.name}/>;
    // } );

    let [mode2, setMode2] = useState( true );

//    console.log( "Items:", items );
    let children : any[] = [];

    if ( mode2 && items.length > 1 ) {
        for ( let i = 0; i < items.length; i += 2 ) {
            let i1 = items[i];
            let i2 = items[i + 1];

            if ( !i2 ) {
                children.push( 
                    <div className="dh-form-row" key={i1.inputId}>
                        <div className="dh-form-col">
                            <FilterElem fv={i1} visible={true} />
                        </div>
                        <div className="dh-form-col">&nbsp;</div>
                    </div>
                );
                i--;
                continue;
            }

            children.push( 
                <div className="dh-form-row" key={i1.inputId}>
                    <div className="dh-form-col">
                        <FilterElem fv={i1} visible={true} />
                    </div>
                    <div className="dh-form-col">
                        <FilterElem fv={i2} visible={true} />
                    </div>
                </div>
            );
        }
    } else {
        children = items.map( value =>
            <FilterElem fv={value} visible={true} key={value.name}/>
        );
    }

    // return <div className="dh-form">
    //         {children}
    //     </div>

    let onResize = (arr)=>{
        let width = arr[0].contentRect.width;
        if ( width == 0 ) return;
        let newMode2 = width > 1100; //  ? "dh-form-two" : "dh-form-one";
        if ( newMode2 === mode2 )
            return;

        setMode2( newMode2 );
    };

    return <ResizeSensor onResize={onResize}>
        <div className="dh-form">
            {children}
        </div>
    </ResizeSensor>;
}
