import {Page} from './Page';
import { utils } from '../utils';
import { FieldInfo } from '../TypeInfo';
import { Application } from '../App';
import { ChildrenFieldValue, FieldValue, FilesFieldValue, IdArrayFieldValue, InlineArrayFieldValue, InlineFieldValue, SelectFieldValue, SubdocumentsFieldValue, SubformByIdValue } from './DocSource';

declare var app: Application;

interface FieldsIndex {
    [key: string]: FieldInfo;
}

interface ValuesIndex {
    [key: string]: FieldValue;
}

export class ViewDocPage extends Page {
    // _changed: boolean = false;
    fieldsIndex: FieldsIndex = {};
    fieldsOrder: string[] = [];
    values: ValuesIndex = {};
    nameIsId = false;
    editMode = false;

    docData?: any;
    // updates?: any;

    beforeSave?: ( doc, page: ViewDocPage )=>void;
    afterSave?: ( doc, page: ViewDocPage )=>void;

    constructor( props ) {
        super( props );
        
        this.mode ||= "show";
        // this.updates = {};
        this.defaultFormName = "ViewForm";
//        if ( !props.hideCommands )
//            this.state.commandsName = "commands";

        if ( props.beforeSave )
            this.beforeSave = props.beforeSave;

        if ( props.afterSave )
            this.afterSave = props.afterSave;
    }

    async init() {
        if ( this.valueMode === "INLINE" ) {
            if ( !this.typeName )
                this.typeName = this.props.docData?.type;

            if ( this.docId == null )
                this.docId = this.props.docData?.id;

            this.initType();
            await this.prepareFields();
        
            if ( this.viewMode === "create" )
                this.createInline();
            
            if ( this.props.docData )
                this.setDocData( this.props.docData );

            if ( !this.isSubform() && !this.props.actions ) {
                await this.requestActions();
            }
        } else {
            this.initType();
            await this.prepareFields();

            if ( this.viewMode === "create" ) {
                await this.startCreate();
            } else {
                this.docId = this.props.docId;
                await this.load();
            }
        }

        if ( this.typeInfo && this.typeInfo.desc.onShow ) {
            this.evalScript( this.typeInfo.desc.onShow, null, true );
        }

        if ( this.viewMode === "edit" || this.viewMode === "create" )
            this.startEdit();

        if ( typeof this.props.onReady === "function" )
            this.props.onReady( this );

        super.init();
    }

    getUrl() {
        return utils.makeUrl( { mode: this.mode, path: this.path, docId: this.docId, viewMode: this.viewMode } );
    }

    /** ИД для замены новой страницей */
    getReplaceKey() {
        if ( this.fixed )
            return;

        return utils.makeUrl( { mode: this.mode, path: this.path } );
    }

    getDoc() {
        let dd = this.docData || {};
        Object.values( this.values ).forEach( v => dd[v.name] = v.getValue() );
        return dd;
    }

    getUpdates() {
        let dd: any = {};
        Object.values( this.values ).forEach( v => {
            if ( v.getUpdate() !== undefined )
                dd[v.name] = v.getValue();
        } );
        return dd;
    }

    /** Заполняет поля формы после получения данных документа
     * @param docData Документ
     */
    setDocData( docData ) {
        if ( docData == null )
            docData = {};

        if ( docData.id != null )
            this.docId = docData.id;

        this.docData = docData;
        for ( let [name,fieldValue] of Object.entries(this.values) ) {
            fieldValue.assign( docData[name] );
        }

        this.evalVisible();
    }

    async changeTypeTo( typeName: string ) {
        this.setTypeName( typeName );
        await this.prepareFields();
        
    }

    async prepareFields() {
        let typeFields = this.typeInfo?.fields;

        if ( !typeFields )
            return;

        let prevValues = this.values;

        this.fieldsIndex = {};
        this.fieldsOrder = [];
        this.values = {};
        let data = this.docData || {};

        let selectSubfields : any = {};
        for ( let typeField of typeFields ) {
            if ( typeField.type == "select" || typeField.input == "select" ) {
                selectSubfields[typeField.name + "_label" ] = true;
                selectSubfields[typeField.name + "_path" ] = true;
            }
        }

        for ( let typeField of typeFields ) {
            if ( typeField.name in selectSubfields )
                continue;

            let field = await this.prepareOneField( typeField );
            this.fieldsIndex[ field.name ] = field;
            this.fieldsOrder.push( field.name );
            let fv: FieldValue | undefined;
            if ( field.type === "subform" ) {
                if ( field.valueMode === "INLINE" ) {
                    fv = new InlineFieldValue( field, data[ field.name ], this );
                } else if ( field.valueMode === "ID" ) {
                    fv = new SubformByIdValue( field, data[ field.name ], this );
                }
            } else if ( field.type === "files" ) {
                fv = new FilesFieldValue( field, data[ field.name ], this );
            } else if ( field.type === "subdocuments" ) {
                fv = new SubdocumentsFieldValue( field, this.docId, this );
            }

            if ( !fv && (field.type === "table" || field.type === "subform") ) {
                if ( field.valueMode === "INLINE_ARRAY" ) {
                    fv = new InlineArrayFieldValue( field, data[ field.name ], this );
                    console.log( "Table value: ", fv );
                } else
                if ( field.valueMode === "ID_ARRAY" ) {
                    fv = new IdArrayFieldValue( field, data[ field.name ], this );
                } else
                if ( field.valueMode === "CHILDREN" ) {
                    fv = new ChildrenFieldValue( field, this.docId, this );
                }
            }

            if ( !fv && (field.type === "select" || field.input === "select") ) {
                let labelInfo = {
                    name: field.name + "_label",
                    type: "string",
                    readonly: true
                } as FieldInfo;

                let labelValue = new FieldValue( labelInfo, data[labelInfo.name], this );
                this.values[ labelValue.name ] = labelValue;

                let pathInfo = {
                    name: field.name + "_path",
                    type: "string",
                    readonly: true
                } as FieldInfo;

                let pathValue = new FieldValue( pathInfo, data[pathInfo.name], this );
                this.values[ pathValue.name ] = pathValue;
             
                fv = new SelectFieldValue( field, data[ field.name ], this, labelValue, pathValue );
            }

            if ( !fv )
                fv = new FieldValue( field, data[ field.name ], this );

            this.values[ field.name ] = fv;
        }

        for ( let field of typeFields ) {
            if ( field.descField && field.descDict ) {
                let ref = this.values[ field.descField ];
                let v = this.values[ field.name ];
                if ( ref && v )
                    v.descRef = ref;
            }
        }

        if ( prevValues ) {
            for ( let fv of Object.values( prevValues ) ) {
                fv.onDestroy();
            }
        }

        for ( let fv of Object.values( this.values ) ) {
            await fv.init();
        }
    }

    async prepareOneField( field: FieldInfo ) : Promise<FieldInfo> {
        let pfield : FieldInfo = { ...field };

        // // Set value
        // let value = pfield.defaultValue;
        // if ( this.updates && field.name in this.updates )
        //     value = this.updates[field.name];
        // else if ( this.docData && field.name in this.docData )
        //     value = this.docData[field.name];

        // if ( field.zeros && value != null ) {
        //     value = String(value).padStart( field.minlen || 1, "0" );
        // }

        // pfield.value = value;
        
        // fill values from valuesDict
        let values = pfield.values;

        if ( pfield.valuesDict ) {
            if ( field.valuesDict == "Status" && this.pathInfo && this.pathInfo.statusValues )
                values = this.pathInfo.statusValues.map( d => [ d.id, d.label ] );
            else {
                let dict = await app.requestDict( pfield.valuesDict );
                if ( dict?.values )
                    values = dict.values.map( vv => [ vv.name, vv.label ] );
            }
        }

        if ( values ) {
            if ( pfield.noKeys )
                pfield.comboValues = values;
            else
                pfield.comboValues = values.map( ([k,v]) => [ k, k + " - " + v ] );
        }

        // if ( !pfield.input )
        //     pfield.input = this.guessInputType( pfield );

        if ( !pfield.place )
            pfield.place = "Main";

        // if ( pfield.input === "subform" ) {
        //     pfield.subform = this.prepareSubform( pfield );
        //     pfield.wide = true;
        //     pfield.hideLabel = true;
        // }

        return pfield;
    }

    getFormValues( desc ) : FieldValue[] {
        let match: (v:any) => boolean;

        if ( typeof desc === "string" ) {
            if ( desc === "@Main" ) {
                match = v => v.fieldInfo.place == null || v.fieldInfo.place === "Main";
            }
            else if ( desc.startsWith( "@" ) ) {
                let place = desc.substring(1);
                match = v => v.fieldInfo.place === place;
            } else {
                match = v => v.name === desc;
            }
        }
        else if ( Array.isArray( desc ) ) {
            match = v => desc.indexOf( v.name ) != -1;
        }

        return this.fieldsOrder.map( name => this.values[name] ).filter( v => v && match( v ) );
    }

    async setFieldAttr( fieldName, attrs ) {
        var prev = this.fieldsIndex[fieldName];
        if ( !prev ) {
            console.error( "Unknown field: ", fieldName );
            return;
        }

        let field = { ...prev, ...attrs };
        field = await this.prepareOneField( field );
        this.fieldsIndex[fieldName] = field;
        this.values[fieldName].setFieldInfo( field );
        this.notify();
    }

    getField( fieldName: string ) {
        return this.fieldsIndex[fieldName];
    }

    // TODO: check
    // fixAmount( field ) {
    //     let value = this.getFieldValue(field);
    //     if ( value == null || value == "" || value.match( /^([1-9]\d+|0)\.\d\d$/ ) || !value.match( /^\d*\.?\d*?$/ ) )
    //         return;

    //     let res = parseFloat( value ).toFixed(2);
    //     if ( res == value )
    //         return;

    //     if ( this.isEditMode() )
    //         this.assignValue( field, res );
    //     else
    //         this.setValue( field, res, false );
    // }

    setInvalid( fieldName: string, text ) {
        this.setFieldAttr( fieldName, { invalid: text != null, invalidText: text } );
    }

    /** Получить текущее значение поля */
    getFieldValue( fieldName: string ) {
        return this.values[fieldName]?.getValue();
    }        
    
    guessInputType_OLD( field ) {
        if ( field.type === "subform" && field.valueMode === "INLINE_ARRAY" && field.expanded && field.allowExpand )
            return "subforms";

        if ( field.type === "subform" || field.type === "docsTable" || field.type === "files" ) {
            return "subform";
        }

        if ( this.isFieldReadonly(field) || !this.isEditMode() ) {
            switch( field.type ) {
                case "bool":
                    return "checkboxRo";
                    
                default:
                    if ( field.highlight )
                        return "highlight";
                    return "textRo";
            }
        }
        
        if ( field.input )
            return field.input;
        
        switch( field.type ) {
            case "bool":
                return "checkbox";
                
            case "date":
                return "date";

            case "datetime":
                return "datetime";
                
            default:
                if ( field.multiline ) return "textarea";
                return "text";
        }
    }

    async load() {
        this.clearError();
        this.cancelEdit();
        
        if ( (this.docId == null && this.props.docName == null ) || this.valueMode === "INLINE" || this.viewMode === "create" ) {
            return;
        }

        this.clearSubforms();
        this.incLoading();

        let withActions = ! this.props.actions;

        try {
            var res = await app.docSvc.getDocData( { docId: this.docId, path: this.path, name: this.props.docName, flags: { noCache: true }, withActions: withActions } );
            if ( res.docData?.type != this.typeName ) {
                await this.changeTypeTo( res.docData.type );
            }

            await this.setDocData( res.docData );
            if ( withActions ) {
                if ( res.actions )
                    this.setActions( res.actions );
                else
                    await this.requestActions();
            }
        }
        catch (err){
            this.setError( "Ошибка загрузки даннных" );
            app.showError( err );
        } finally {
            this.decLoading();
        }
    }

    private async startCreate() {
        if ( this.typeInfo == null ) {
            this.setError( "Не найден тип данных" );
            return;
        }

        this.fixed = true;

        if ( ! this.props.actions )
            await this.requestActions();

        let idField = this.getField( "id" );
        let updates: any;

        if ( !idField || idField.type != "integer" || this.typeInfo?.desc.nameIsId ) {
            // Не запрашиваем ID
            let nameField = this.getField( "name" );
            if ( nameField?.allowCreate || this.typeInfo?.desc.nameIsId ) {
                this.nameIsId = true;
                await this.setFieldAttr( "name", { readonly: false, notNull: true } );
            } else if ( idField ) {
                await this.setFieldAttr( "id", { readonly: false, place: "Main", notNull: true } );
            }
            if ( this.props.newDocData )
                updates = { ...this.props.newDocData };
            else
                updates = {};
        } else {
            try {
                this.incLoading();

                let res = await app.docSvc.getNewDoc( this.typeInfo.name, this.path );
                // this.docData = res.docData; 
                this.docId = res.docData.id;
                if ( this.props.newDocData )
                    updates = { ...this.props.newDocData, ...res.docData };
                else
                    updates = { ...res.docData };
            } catch ( err: any ) {
                this.showError("Ошибка подготовки данных: " + err.message ); 
                return;
            } finally {
                this.decLoading();
            }
        }

        updates.path = this.path;
        if ( !updates.type )
            updates.type = this.typeInfo.name;
            
        this.docData = updates;

        for ( let [name,fieldValue] of Object.entries(this.values) ) {
            let value = (name in updates) ? updates[name] : fieldValue.fieldInfo.defaultValue;
            fieldValue.assign( value );
        }

        // this.ready = true;
        // this.timestamp = Date.now();

        // if ( this.typeInfo && this.typeInfo.desc.onShow ) {
        //     this.evalScript( this.typeInfo.desc.onShow );
        // }

        // this.startEdit();
        // this.prepareTitle();
    }

    createInline() {
        var updates = this.props.newDocData || {};

        for ( let [name,fieldValue] of Object.entries(this.values) ) {
            let value = (name in updates) ? updates[name] : fieldValue.fieldInfo.defaultValue;
            if ( value != null )
                fieldValue.assign( value );
        }

        if ( this.values.type && this.values.type.getValue() == null && this.typeName )
            this.values.type.assign( this.typeName );
    }
    
    //settypeInfo( t ) {
    //    super.settypeInfo( t );
    //    this.prepareSubforms();
    //}

    /** Вызывается при изменении значения через интерфейс */
    // onFieldChange( field, value ) {

    //     if ( typeof field === "string" )
    //         field = this.getField( field );

    //     if ( !this.updates ) this.updates = {};

    //     if ( typeof value === "string" ) {
    //         if ( field.digits || field.type === "integer" || field.type === "digits" ) {
    //             value = value.replace( /\D+/g, "" );
    //         } else if ( field.type === "number" || field.type === "amount" ) {
    //             value = value.replace(/[.]/g,",").replace(/,([^,]*)$/g,".$1").replace(/[^0-9.]/g, "" );
    //         } else if (field.type === "string") {
    //             value = value.replace( /[«»]/g, '"' );
    //         }
    //         if ( field.upper ) {
    //             value = value.toUpperCase();
    //         }
    //     }

    //     this.updates[ field.name ] = value;
        
    //     let i = this.state.fields.findIndex( f => f.name===field.name );
    //     if ( i >= 0 ) {
    //         // this.updateState( [ "fields", i, "value" ], value );
    //         this.updateState( [ "fields", i ], this.fieldToDesc( field ) );
    //     }

    //     if ( field.descDict )
    //         this.fillPseudoField( field, value );

    //     if ( field.onChange ) {
    //         this.evalScript( field.onChange, {field,value}, true );
    //     }
            
    //     if ( this.props.onChange != null )
    //         this.props.onChange( { ...this.docData, ...this.updates } );
    // }

    /** Заполнить значение поля программным способом */
    assignValue( field: string, value ) {
        this.values[field]?.assign( value );
    }

    setFieldValues( fieldName, values ) {
        this.setFieldAttr( fieldName, { values: values, valuesDict: null } );
    }

    // /** Присвоить значение псевдо-поля. Не сохраняется. */
    // setValue( fieldName, value, dontRefresh ) {
    //     if ( this.docData == null )
    //         this.docData = {};

    //     if ( this.docData[fieldName] === value )
    //         return;

    //     this.docData[fieldName] = value;
    //     if ( !dontRefresh )
    //         this.updateField( fieldName );
    //         // this.prepareFields();
    // }

    setFieldReadonly( field: string, ro: boolean ) {
        this.setFieldAttr( field, { readonly: ro } );
    }

    isFieldReadonly( field: string ) {
        return this.fieldsIndex[field]?.readonly;

//         let fieldName;
//         if ( typeof field === "string" ) {
//             fieldName = field;
//             field = this.getField( fieldName );
//         } else {
//             fieldName = field.name;
//         }

// //        if ( this.readonlyOverride != null ) {
// //            let ro = this.readonlyOverride[ fieldName ];
// //            if ( ro === true || ro === false )
// //                return ro;
// //        }

//         if ( field && this.viewMode=="create" && field.allowCreate )
//             return false;
        
//         return field == null || field.readonly;
    }

    // fillPseudoField( field, value, dontRefresh ) {
    //     if ( value == null || value === "" ) {
    //         this.setValue( field.descField, null, dontRefresh );
    //         return;
    //     }

    //     this.setValue( field.descField, "[loading]", dontRefresh );

    //     if ( field.zeros ) {
    //         value = String( value ).padStart( field.minlen, "0" );
    //     }
        
    //     window.app.getDictValue( field.descDict, value ).then(
    //         obj => this.setValue( field.descField, obj == null ? "" : obj.label, false )
    //     ).catch( err => this.log( err ) );
    // }
    
    allowEdit() {
        return true;
    }
    
    allowCreate() {
        if ( !this.typeInfo || this.typeInfo.desc.readonly )
            return false;
        
        return true;
    }
    
    allowSave() {
        return this.isEditMode() && !this.isSubform();
    }
    
    isEditMode() {
        return this.editMode;
    }

    startEdit() {
        if ( this.editMode )
            return;

        this.fixed = true;
        
        // if ( this.isSubform() && this.valueMode === "ID" ) {
        //     this.allowSelectDocFlag = true;
        //     // this.prepareActions();
        //     return;
        // }
        
        if ( !this.ready ) {
            this.readyEvt.wait().then( ()=>this.startEdit() );
            return;
        }

        if ( this.isLoading() ) {
            app.showError( "Идет загрузка данных" );
            return;
        }

        this.editMode = true;

        if ( this.typeInfo?.desc.onEdit ) {
            this.evalScript( this.typeInfo.desc.onEdit );
        }
        
        // this.prepareFields();

//        this.updates = {};
        for ( let name of this.fieldsOrder ) {
            let fieldValue = this.values[name];
            if ( fieldValue.fieldInfo.readonly || fieldValue.disabled )
                continue;

            fieldValue.startEdit();
        }

        // this.prepareActions();

        if ( this.props.onStartEdit )
            this.props.onStartEdit( this );

        this.evalVisible();

        this.notify();
    }

    cancelEdit() {
        if ( !this.isEditMode() )
            return;

        if ( this.viewMode === "create" ) {
            this.close();
            return;
        }

        this.clearSubforms();
        
        // if ( this.isSubform() && this.valueMode === "ID" && this.allowSelectDocFlag ) {
        //     this.allowSelectDocFlag = false;
        //     //this.prepareActions();
        //     return;
        // }

        this.editMode = false;
        for ( let fieldValue of Object.values( this.values ) ) {
            fieldValue.cancelEdit();
        }

        // this.updates = {};
        //this.prepareActions();
        //this.prepareFields();
        this.evalVisible();

        this.notify();
    }

    onDeleteOk() {
        this.close();
        app.onDataChanged( this.path, this );
    }

    // getUpdatedDoc() {
    //     this.applySubformsToUpdates();
    //     if ( this.updates ) {
    //         return { ...this.docData, ...this.updates };
    //     }
    //     return this.docData;
    // }

    // applySubformsToUpdates() {
    //     for ( let fdesc of this.state.fields ) {
    //         if ( fdesc.subforms ) {
    //             let res = fdesc.subforms.map( subform => subform.getUpdatedDoc() );
    //             this.updates[ fdesc.name ] = res;
    //         }
    //     }
    // }

    async save() {
        if ( this.valueMode === "INLINE" ) {
            app.showError( "Нельзя вызвать сохранение у встроенного объекта" );
            return;
        }

        this.beforeSave?.( this.getDoc(), this );
        if ( this.typeInfo?.desc.verifyBeforeSave )
            if ( !this.verifyDoc() )
                return;

        await this.saveFiles();

        let res : Promise<any>;
        if ( this.viewMode === "create" ) {
            let doc = this.getDoc();
            if ( doc.id == null && this.nameIsId )
                doc.id = doc.name;

            this.docId = doc.id;

            if ( !doc.type )
                doc.type = this.typeName;

            res = app.docSvc.createDoc( { docData: doc } );
        } else {
            let updates = this.getUpdates();
            res = app.docSvc.saveDoc( { docId: this.docId, path: this.path, updates: updates, flags: this.props.saveFlags } );
        }
         
        this.incLoading();
        try {
            let res2 = await res;
            app.showSuccess( res2.resultText || "Документ сохранен" );
        } catch ( err: any) {
            app.showError( err.message );
            return;
        } finally {
            this.decLoading();
        }

        app.onDataChanged( this.path, this );

        this.afterSave?.( this.getDoc(), this );
        
        if ( this.viewMode === "create" ) {
            this.close();
            app.showView( { mode: "show", path: this.path, docId: this.docId, target: this.props.target } );
        } else {
            this.load();
        }
    }

    async saveFiles() {
        let filesValues = Object.values( this.values ).filter( v => v.fieldInfo.type === "files" );
        for ( let fileValue of filesValues ) {
            let files = fileValue.getValue();
            if ( !files || files.length == 0 )
                continue;

            let newFiles = files.filter( f => (f.id === undefined || f.id < 0 ) && f._file != null );
            if ( newFiles.length == 0 )
                continue;

            let req = {
                titalFiles: String(newFiles.length),
                sourceDoc: String(this.docId),
                sourcePath: this.path
            }

            let findex = {};
            newFiles.forEach( (f,i) => {
                req[ "file-" + i ] = f._file;
                findex[ "file-" + i ] = f;
            } );

            let res = await app.filesSvc.sendFiles( req );
            if ( res.ids != null ) {
                for ( let k in res.ids ) {
                    let f = findex[ k ];
                    f.id = res.ids[ k ];
                    //f.fileStatus = "saved";
                    //delete f.fileStatusStr;
                    //delete f.sizeStr;
                    delete f._file;
                }
            }
            fileValue.setUpdate( files );
        }
    }

    async deleteDoc() {
        if ( this.valueMode === "INLINE" ) {
            app.showError( "Нельзя удалить встроенный объект" );
            return;
        }
        //let jobId = Date.now();
        //this.addJob( jobId, { icon: "", text: "Сохранение данных" } );

        // let doc = this.;

        let res = await app.docSvc.deleteDoc( { docId: this.docId, path: this.path } );
        app.showSuccess( res.resultText || "Документ удален" );
        this.onDeleteOk();
        return true;
    }
    
    getDefaultLayout() {
        if ( !this.isDialog() )
            return {
                type: "VBox",
                top: {
                    type: "Buttons",
                    placeName: "commands"
                },
                bottom: {
                    type: "Place",
                    placeName: "Main",
                    placeType: "table"
                }
            };
        
        return {
            type: "VBox",
            top: {
                type: "Buttons",
                placeName: "commands"
            },
            bottom: {
                type: "VBox",
                top: {
                    type: "Place",
                    placeName: "Main",
                    placeType: "table"
                },
                bottom: {
                    type: "Buttons",
                    placeName: "dialogCommands"
                }
            }
        };
    }

    // allowSelectDoc() {
    //     return this.allowSelectDocFlag;
    // }
    
    // showSelectDoc() {
    //     if ( this.valueMode != "ID" ) return;
        
    //     let page = new DocListPage( { typeName: this.baseTypeName || this.typeName, 
    //         selectionMode: "single", 
    //         target: "dialog",
    //         onSelect: (doc)=>this.onSelectFromDialog(doc.id)
    //     } );
            
    //     this.addDialog( page );
    // }
    
    // onSelectFromDialog( docId ) {
    //     if ( this.valueMode != "ID" ) return;

    //     this.docId = docId;
    //     if ( this.props.onChange )
    //         this.props.onChange( docId );
        
    //     this.load();
    // }

    viewSource() {
        // app.showWarning( "Не реализовано" );
        let text = JSON.stringify( this.docData, null, 4 );
        app.showView( {
            mode: "source",
            text,
            target: "dialog",
            language: "json"
        } );
    }

    onOk() {
        if ( this.isEditMode() && this.typeInfo?.desc.verifyBeforeSave ) {
            if ( !this.verifyDoc() )
                return;
        }
        if ( this.props.onOk ) {
            let doc = this.getDoc();
            this.props.onOk( doc, this );
        } else {
            this.save();
        }
    }

    // fieldToPrintDesc( field ) {
    //     let fdesc = {
    //         name: field.name,
    //         label: field.label,
    //         input: field.type === "subform" ? "subform" : "printText",
    //         inputId: this.pageUid + "_" + field.name,
    //         field: field,
    //         place: field.place || "Main"
    //     };

    //     if ( field.type === "subform" && field.valueMode === "INLINE_ARRAY" && field.allowExpand )
    //         fdesc.input = "subforms";

    //     fdesc.value = this.getInputValue( field, fdesc.input );

    //     switch( typeof fdesc.value ) {
    //         case "boolean":
    //             fdesc.value = fdesc.value ? "да" : "нет";
    //             break;

    //         case "number":
    //             fdesc.value = String( fdesc.value );
    //             break;

    //         case "undefined":
    //             if ( fdesc.input === "printText" )
    //                 fdesc.value = "";
    //             break;

    //         case "object":
    //             if ( fdesc.input === "printText" && fdesc.value === null )
    //                 fdesc.value = "";
    //             break;
    //     }

    //     if ( fdesc.input === "subforms" ) {
    //         let data = fdesc.value || [];
    //         fdesc.subforms = data.map( (record, i)=>this.prepareSubformExpand( field, record, i ) );
    //         fdesc.contents = fdesc.subforms.map( s => s.renderForPrint() );
    //     }

    //     if ( fdesc.input === "subform" && this.subforms ) {
    //         fdesc.subform = this.prepareSubform( field, fdesc );
    //         if ( fdesc.subform )
    //             fdesc.content = fdesc.subform.renderForPrint();

    //         fdesc.wide = true;
    //         fdesc.hideLabel = true;
    //     }

    //     return fdesc;
    // }

    verifyDoc() {
        let t = this.typeInfo;
        if ( t == null )
            return true;

        // let doc = this.getDoc();

        let res : any = { valid: true, errors: [] };

        for ( let v of Object.values( this.values ) ) {
            if ( v.isInvalid() ) {
                res.valid = false;
                let chk = v.getInvalidText();
                res.errors.push( chk );
                app.showError( `Поле ${v.fieldInfo.label}: ${chk}` );
            }
        }

        return res.valid;
    }

    getDialogSize() : [ string, string ] {
        if ( this.props.dialogSize )
            return this.props.dialogSize;

        if ( this.typeInfo?.desc.dialogSize )
            return this.typeInfo.desc.dialogSize;
            
        let len = this.fieldsOrder.length;

        if ( !len || len > 20 )
            return [ "80%", "90%" ];

        if ( len > 15 ) {
            return [ "1000px", "90%" ];
        }

        return [ "1000px", (150 + len*50) + "px" ];
    }

    evalVisible() {
        for ( let v of Object.values( this.values ) ) {
            v.evalVisible();
        }
    }
}

// export default ViewDocPage;