import {Page} from './Page';
//import 'highlight.js/styles/github.css';
//import hljs from 'highlight.js/lib/index.js';

export class SourceViewPage extends Page {
    text: string;
    pages?: string[];
    language: string;

    constructor( props ) {
        super( props );
        this.text = props.text;
        this.pages = props.pages;
        this.language = props.language || "json";
    }

    getState() {
        let style = {
            whiteSpace: "pre"
        };

        return {
            type: "Content",
            content: <div className="dh-body dh-scroll" style={{height: "100%"}}>
                <div className="dh-monospace" style={style}>{this.text}</div>
            </div>
        }
    }

    isEasyClose() { return true; }

    // renderForPrint() {
    //     if (this.pages){
    //         var list = [];
    //         for (var page of this.pages){
    //             var last = page === this.pages[this.pages.length]
    //             list.push(<pre style={{pageBreakAfter: last ? null :"always"}}>{page}</pre>);
    //         }
    //         return <React.Fragment>{list}</React.Fragment>
    //     }
    //     else {
    //         return <pre>{this.text}</pre>;
    //     }
    // }
}
