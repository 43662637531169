import {ReactEvt, utils} from '../utils';
import { TableSource } from './TableSource';
import { Application } from '../App';
import { BaseListPage } from './BaseListPage';

declare var app: Application;

function _moveUp( docs: any[], selected ) {
    for ( let i = 0; i < docs.length - 1; ++i ) {
        let doc = docs[i];
        let nextDoc = docs[i+1];
        if ( selected[doc.id] ) continue;
        if ( selected[nextDoc.id] ) {
            docs[i] = nextDoc;
            docs[i+1] = doc;
        }
    }
}

export class InlineArrayPage extends BaseListPage {
    tableEvt: ReactEvt;
    tableSource!: TableSource;
    editMode = false;
    selectMode = false;
    documents: any[] = [];
    needReload = false;

    constructor( props ) {
        super( props );

        this.tableEvt = new ReactEvt( ()=>this.tableSource );
        
        this.formName = props.formName || "InlineListForm";
        this.mode = props.mode || "edit_list";

        this.documents = props.docs || [];
    }

    async init() {
        this.initType();

        this.tableSource = new TableSource( this );
        await this.tableSource.init();

        if ( typeof this.props.onReady === "function" )
            this.props.onReady( this );

        this.setActions( EDIT_ACTIONS );

        if ( this.viewMode == "edit_docs" )
            this.startEdit();

        super.init();
    }

    startEdit() {
        if ( this.editMode )
            return;

        this.fixed = true;

        this.editMode = true;
        this.selectMode = false;
        this.notify();
    }

    endEdit() {
        if ( !this.editMode ) {
            this.endSelect();
            return;
        }

        this.editMode = false;
        this.selectMode = false;
        this.tableSource.endSelectMode();
        this.notify();
    }

    startSelect() {
        if ( this.selectMode || !this.editMode )
            return;

        this.selectMode = true;
        this.tableSource.startSelectMode();
        this.notify();
    }

    endSelect() {
        if ( !this.selectMode )
            return;

        this.selectMode = false;
        this.tableSource.endSelectMode();
        this.notify();
    }

    
    sortByOrder( docs, orders ) {
        if ( orders.length == 0 )
            return docs;

        function mkCmp( prev, field, desc ) {
            return function( a, b ) {
                let res;
                if ( prev != null ) {
                    res = prev(a,b);
                    if ( res != 0 )
                        return res;
                }

                if ( a[field] < b[field] )
                    res = -1;
                else if ( a[field] > b[field] )
                    res = 1;
                else
                    res = 0;
                 
                return desc ? -res : res;
            };
        }

        var cmp: any = null;
        for ( let o of orders ) {
            cmp = mkCmp( cmp, o.field, o.desc );
        }

        docs.sort( cmp );
    }
    
    onRowClick( doc ) {
        if ( this.props.onRowClick ) {
            this.props.onRowClick( doc, this );
            return;
        }

        var options : any = {
            mode: "show",
            target: "dialog",
            docData: doc,
            typeName: this.typeName,
            valueMode: "INLINE"
        };

        if ( this.editMode ) {
            options.viewMode = "edit";
            options.onOk = ( doc_, page ) => {
                this.replaceDoc( doc, doc_ );
                page.close();
            }
        }

        app.showView( options );
    }

    onAdd() {
        if ( this.props.addFromPath ) {
            let options = {
                mode: "list",
                viewMode: "select",
                target: "dialog",
                path: this.props.addFromPath,
                typeName: this.typeName,
                onOk: (docs, page) => {
                    this.doAddFromPath( docs );
                    page.close();
                }
            };

            if ( this.props.addFilterScript ) {
                this.evalScript( this.props.addFilterScript, { options } );
            }
    
            app.showView( options );
        } else {
            let options = {
                mode: "view",
                viewMode: "create",
                target: "dialog",
                typeName: this.typeName,
                valueMode: "INLINE",
                onOk: (data, page) => {
                    this.doAddInline( data );
                    page.close();
                }
            }
            app.showView( options );
        };
    }

    doAddInline( doc ) {
        if ( !doc.id ) {
            doc.id = utils.randomString( 10 );
        }

        this.documents = [ ...this.documents, doc ]
        this.notify();
    }

    doAddFromPath( docs ) {
        this.documents = utils.uniqArray( [this.documents, docs], doc => doc.id );
        this.notify();
    }

    onDeleteAll() {
        this.documents = [];
        this.notify();
    }

    onDeleteSelected() {
        let selected = this.tableSource.getSelectedIds();
        if ( selected.length == 0 ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let idx = Object.fromEntries( selected.map( s => [ s, true ] ) );
        this.documents = this.documents.filter( doc => !idx[doc.id] );
        this.notify();
    }

    replaceDoc( doc1, doc2 ) {
        this.documents = this.documents.map( doc => doc.id === doc1.id ? doc2 : doc );
    }

    onOk() {
        if ( this.props.onOk ) {
            this.props.onOk( this.documents, this );
        } else
            this.close();
    }
    
    setNeedReload() {}
    
    onShowPage() {
    }

    getSelectedDoc() {
        let docs = this.tableSource.getSelectedDocs();
        if ( !docs || docs.length == 0 ) {
            app.showWarning( "Не выделена строка" );
            return;
        }

        if ( docs.length > 1 ) {
            app.showWarning( "Выделено более одной строки" );
            return;            
        }

        return docs[0];
    }

    getSelectedDocs() {
        return this.tableSource.getSelectedDocs();
    }

    getSelectedIds() {
        if ( !this.tableSource.hasSelection() )
            return;

        return this.tableSource.getSelectedIds();
    }

    onMoveUp() {
        if ( !this.tableSource.hasSelection() ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let docs = [ ...this.documents ];
        _moveUp( docs, this.tableSource.selected );
        this.documents = docs;
        this.notify();
    }

    onMoveDown() {
        if ( !this.tableSource.hasSelection() ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let docs = [ ...this.documents ];
        docs.reverse();
        _moveUp( docs, this.tableSource.selected );
        docs.reverse();
        this.documents = docs;
        this.notify();
    }
}

export class IdArrayPage extends BaseListPage {
    tableEvt: ReactEvt;
    tableSource!: TableSource;
    editMode = false;
    selectMode = false;
    idArray: any[];
    documents: any[] = [];
    needReload = false;

    constructor( props ) {
        super( props );
        this.tableEvt = new ReactEvt( ()=>this.tableSource );
        
        this.formName = props.formName || "InlineListForm";
        this.mode = props.mode || "edit_list";

        this.idArray = Array.isArray( props.idArray ) ? props.idArray : [];
    }

    async init() {
        this.initType();

        this.tableSource = new TableSource( this );
        await this.tableSource.init();

        await this.load();

        this.setActions( EDIT_ACTIONS );

        super.init();
    }

    setIdArray( arr: any[] ) {
        if ( arr == undefined ) {
            arr = [];
        } else
        if ( !Array.isArray( arr ) ) {
            console.warn( "Invalid array", arr );
            throw Error( "Invalid array" );
        }
        this.idArray = arr;
        this.load();
    }

    startEdit() {
        if ( this.editMode )
            return;

        this.fixed = true;

        this.editMode = true;
        // this.tableSource.startSelectMode();
        this.notify();
    }

    endEdit() {
        if ( !this.editMode ) {
            this.endSelect();
            return;
        }

        this.editMode = false;
        this.selectMode = false;
        this.tableSource.endSelectMode();
        this.notify();
        //this.endSelect();
    }

    startSelect() {
        if ( this.selectMode || !this.editMode )
            return;

        this.selectMode = true;
        this.tableSource.startSelectMode();
        this.notify();
    }

    endSelect() {
        if ( !this.selectMode )
            return;

        this.selectMode = false;
        this.tableSource.endSelectMode();
        this.notify();
    }
    
    getFilter() : any {
        return { id: { oper: "in", values: this.idArray } };
    }
    
    sortByOrder( docs, orders ) {
        if ( orders.length == 0 )
            return docs;

        function mkCmp( prev, field, desc ) {
            return function( a, b ) {
                let res;
                if ( prev != null ) {
                    res = prev(a,b);
                    if ( res != 0 )
                        return res;
                }

                if ( a[field] < b[field] )
                    res = -1;
                else if ( a[field] > b[field] )
                    res = 1;
                else
                    res = 0;
                 
                return desc ? -res : res;
            };
        }

        var cmp: any = null;
        for ( let o of orders ) {
            cmp = mkCmp( cmp, o.field, o.desc );
        }

        docs.sort( cmp );
    }
    
    async load() {
        this.clearError();
        
        this.needReload = false;

        if ( this.idArray.length == 0 ) {
            this.documents = [];
            this.tableEvt.notify();
            return;
        }


        let filter = this.getFilter();
        
        let flags : any = {}; // { noSort: this.filterChanged };

        // if ( this.beforeDocumentsLoad ) {
        //     this.beforeDocumentsLoad( { filter, flags } );
        // }
        
        this.incLoading();

        try {
            let res;
            try {
                //console.warn( Error( "Before getDocList" ) )
                //console.log( "Page: ", this );
                let res = await app.docSvc.getDocList( { filter, path: this.path, flags } );
                this.documents = res.documents;
                this.tableEvt.notify();
            } catch ( err : any ) {
                this.setError( "Ошибка загрузки даннных" );
                app.showError( err.message );
            }
        } finally {
            this.decLoading();
        }
    }

    onRowClick( doc ) {
        if ( this.props.onRowClick ) {
            this.props.onRowClick( doc, this );
            return;
        }

        var options : any = {
            mode: "view",
            target: "dialog",
            docId: doc.id,
            path: doc.path,
            typeName: this.typeName,
        };

        app.showView( options );
    }

    onAdd() {
        // TODO: select from many?

        let options = {
            mode: "list",
            viewMode: "select",
            target: "dialog",
            path: this.path,
            typeName: this.typeName,
            onOk: (docs, page) => {
                this.doAddFromPath( docs );
                page.close();
            }
        };

        app.showView( options );
    }

    doAddFromPath( docs ) {
        let keys = docs.map( doc => doc.id );
        this.idArray = utils.uniqArray( [ this.idArray, keys ], k => k );
        this.load();
    }

    onDeleteAll() {
        this.documents = [];
        this.idArray = [];
        this.notify();
    }

    onDeleteSelected() {
        let selected = this.tableSource.getSelectedIds();
        if ( selected.length == 0 ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let idx = Object.fromEntries( selected.map( s => [ s, true ] ) );
        this.idArray = this.idArray!.filter( s => !idx[s] );
        this.load();
    }

    replaceDoc( doc1, doc2 ) {
        this.idArray = this.idArray.map( id => id === doc1.id ? doc2.id : id );
    }

    onOk() {
        if ( this.props.onOk ) {
            this.props.onOk( this.idArray, this );
        } else
            this.close();
    }

    setNeedReload() {
        if ( app.currentPage === this )
            this.load();
        else
            this.needReload = true;
    }
    
    onShowPage() {
        if ( this.needReload )
            this.load();
    }

    getSelectedDoc() {
        let docs = this.tableSource.getSelectedDocs();
        if ( !docs || docs.length == 0 ) {
            app.showWarning( "Не выделена строка" );
            return;
        }

        if ( docs.length > 1 ) {
            app.showWarning( "Выделено более одной строки" );
            return;            
        }

        return docs[0];
    }

    getSelectedDocs() {
        return this.tableSource.getSelectedDocs();
    }

    getSelectedIds() {
        if ( !this.tableSource.hasSelection() )
            return;

        return this.tableSource.getSelectedIds();
    }

    onMoveUp() {
        if ( !this.tableSource.hasSelection() ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let docs = [ ...this.documents ];
        _moveUp( docs, this.tableSource.selected );
        this.documents = docs;
        this.idArray = docs.map( doc => doc.id );
        this.notify();
    }

    onMoveDown() {
        if ( !this.tableSource.hasSelection() ) {
            app.showWarning( "Не выделены строки" );
            return;
        }

        let docs = [ ...this.documents ];
        docs.reverse();
        _moveUp( docs, this.tableSource.selected );
        docs.reverse();
        this.documents = docs;
        this.idArray = docs.map( doc => doc.id );
        this.notify();
    }

}

const EDIT_ACTIONS = [
    {
        id: "add",
        label: "Добавить",
        icon: "plus",
        place: "listCommands",
        script: "page.onAdd()",
        visible: "page.editMode",
    },
    {
        id: "edit",
        label: "Режим выделения",
        icon: "edit",
        place: "listCommands",
        script: "page.startSelect()",
        visible: "page.editMode && !page.selectMode"
    },
    {
        id: "endEdit",
        label: "Режим просмотра",
        icon: "tick",
        place: "listCommands",
        script: "page.endSelect()",
        visible: "page.editMode && page.selectMode"
    },
    {
        id: "moveUp",
        label: "Поднять",
        icon: "arrow-up",
        place: "listCommands",
        script: "page.onMoveUp()",
        visible: "page.editMode && page.selectMode"
    },
    {
        id: "moveDown",
        label: "Опустить",
        icon: "arrow-down",
        place: "listCommands",
        script: "page.onMoveDown()",
        visible: "page.editMode && page.selectMode"
    },
    {
        id: "deleteSelected",
        label: "Удалить выделенные",
        icon: "delete",
        highlight: "danger",
        place: "listCommands",
        script: "page.onDeleteSelected()",
        visible: "page.editMode && page.selectMode"
    },
    {
        id: "deleteAll",
        label: "Удалить все",
        icon: "delete",
        highlight: "danger",
        place: "listCommands",
        script: "page.onDeleteAll()",
        visible: "page.editMode"
    }
]

