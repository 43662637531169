import React, { useRef } from "react";
import { BlockBase, MiniButton, SuperLayout } from './SuperLayout';
import { BoxBlock } from "./BoxBlock";
import { Application } from "../App";
import { Menu, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import { Page } from "../pages/Page";
import { ReactEvt } from "../utils";
import { PageComponent } from "../elems/PageComponent";

declare var superLayout: SuperLayout;
declare var app: Application;

const HEADER_SY = 24;

export class PagesBlock implements BlockBase {
    fixed = false;
    pages: Page[];
    weight: number;
    current: number;
    maximized = false;
    parent?: BlockBase;
    revt: ReactEvt;
//    state: any;
    callbacks: PB_Callbacks;

    constructor( pages ) {
        this.pages = pages;
        this.weight = 100;
        this.current = 0;
        this.revt = new ReactEvt( ()=>this.getState() );
        this.callbacks = new PB_Callbacks( this );
    }

    onTabClick( pageId ) {
        let pos = this.pages.findIndex( p => p.pageId === pageId );
        if ( pos >= 0 ) {
            this.current = pos;
            this.notify();
        }
    }

    updVisible() {
        for ( let i = 0; i < this.pages.length; ++i ) {
            let model = this.pages[i];
            if ( i === this.current )  {
                if ( !model.visible )
                    model.setVisible();
            } else {
                if ( model.visible )
                    model.setHidden();
            }
        }
    }

    addPage( page ) {
        this.pages.push( page );
        this.current = this.pages.length - 1;
        this.notify();
    }

    findBlockByPage( pageId ) : BlockBase | null {
        if ( this.pages.findIndex( p => p.pageId === pageId ) >= 0 ) return this;
        return null;
    }

    replaceOrAddPage( pageId, page ) {
        let i = 0;
        for ( let p of this.pages ) {
            if ( p.pageId === pageId ) {
                if ( p.fixed )
                    break;

                this.pages[i] = page;
                this.current = i;
                this.notify();
                return;
            }
            i++;
        }
        this.addPage( page );
    }

    notify() {
        this.updVisible();
        this.revt.notify();
    }

    delPage( pageId ) {
        let pos = this.pages.findIndex( p => p.pageId === pageId );
        return this.delByNo( pos );
    }

    delByNo( pos ) {
        if ( pos >= 0 ) {
            if ( this.current >= pos && this.current > 0 )
                this.current--;
            
            let old = this.pages.splice( pos, 1 );
            if ( this.pages.length == 0 && this.maximized ) {
                superLayout.restoreBlock();
                // window.dispatch( { actionType: "minimizeBlock" } );
                this.maximized = false;
            }

            if ( this.pages.length == 0 && !this.fixed && this.parent )
                this.parent.deleteBlock( this );
            else
                this.notify();

            return old[0];
        }
    }

    onCloseCurrent() {
        let page = this.pages[this.current];
        // this.delByNo( this.current )
        page?.close();
    }

    render( props ) {
        return <PagesBlockComponent block={this} {...props} />;
    }

    getState() {
        return {
                type: "pages",
                urls: this.pages.map( p => p.getUrl() ),
//                pages: this.pages,
                current: this.current,
                weight: this.weight,
                fixed: this.fixed,
                isRoot: this.parent === superLayout,
                maximized: this.maximized
        };
    }

    setFixed( f ) {
        this.fixed = f;
        this.notify();
    }

    setCurrent( no ) {
        this.current = no;
        this.notify();
    }

    getFBox( box ) {
        // is over header ?
        if ( box.y >= 0 && box.y < HEADER_SY ) {
            return { x: 0, y: 0, sx: box.sx, sy: HEADER_SY, block: this, mode: "page" };
        }

        // Over body page ?
        //return { x: 0, y: 20, sx: box.sx, sy: box.sy - 20, block: this, mode: "page" };

        let x = box.x;
        let sx = box.sx;
        let y = box.y - HEADER_SY;
        let sy = box.sy - HEADER_SY;
        let values = [ x, y, sx - x, sy - y ].map( a => Math.abs(a) );
        let min = Math.min( ...values );
        if ( min === values[0] ) {
            return { x: 0, y: HEADER_SY, sx: sx / 2, sy, block: this, mode: "left" };
        }
        if ( min === values[1] ) {
            return { x: 0, y: HEADER_SY, sx, sy: sy / 2, block: this, mode: "top" };
        }
        if ( min === values[2] ) {
            return { x: sx / 2, y: HEADER_SY, sx: sx / 2, sy, block: this, mode: "right" };
        }
        if ( min === values[3] ) {
            return { x: 0, y: HEADER_SY + sy / 2, sx, sy: sy / 2, block: this, mode: "bottom" };
        }
    }

    movePage( fbox, movBlock, movNo ) {
        if ( movBlock === this ) {
            if ( fbox.mode === "page" || (movBlock.pages.length == 1 && !movBlock.fixed ) )
                return;
        }

        let page = movBlock.pages[ movNo ];
        
        if ( fbox.mode === "page" ) {
            this.addPage( page );
            movBlock.delPage( page.pageId );
            return;
        }

        let newThis;

        let parent : BlockBase = this.parent as any;

        switch( fbox.mode ) {
            case "left": {
                newThis = new BoxBlock( [ new PagesBlock( [ page ] ), this ], false );
                parent.replaceBlock( this, newThis );
                break;
            }

            case "right": {
                newThis = new BoxBlock( [ this, new PagesBlock( [ page ] ) ], false );
                parent.replaceBlock( this, newThis );
                break;
            }

            case "top": {
                newThis = new BoxBlock( [ new PagesBlock( [ page ] ), this ], true );
                parent.replaceBlock( this, newThis );
                break;
            }

            case "bottom": {
                newThis = new BoxBlock( [ this, new PagesBlock( [ page ] ) ], true );
                parent.replaceBlock( this, newThis );
                break;
            }

            default:
                return;
        }

        movBlock.delPage( page.pageId );
        // this.addPage( page );
    }

    onClose() {
        this.fixed = false;
        if ( this.pages.length == 0 ) {
            if ( this.parent ) this.parent.deleteBlock( this );

            superLayout.checkEmpty();
            return;
            // window.dispatch( {
            //     actionType: "layoutCheckEmpty",
            //     forPage: "SL",
            //     block: this
            // } );
        }
        // Блок сам закроется, когда не будет страниц
        [ ...this.pages ].forEach( p=>p.close() );
    }

    getActivePage() {
        return this.pages[ this.current ];
    }

    setPageVisible( pageId ) : void {
        let no = this.pages.findIndex( p => p.pageId === pageId );
        if ( no === -1 )
            return;

        this.setCurrent( no );
    }    

    // unused
    deleteBlock( block ) : void {};
    replaceBlock( prevBlock, newBlock ): void {};
    checkEmpty(): void {};
}

// Set global
(window as any).PagesBlock = PagesBlock;

class PB_Callbacks {
    moving = false;
    down = false;
    ref?: { current: HTMLElement };
    wgt?: HTMLElement;
    moveX = 0;
    moveY = 0;
    pointerId: any;
    no = 0;
    block: PagesBlock;

    constructor( block ) {
        // this.ref = ref;
        this.block = block;
    }

    onPointerDown( evt, no ) {
        if ( this.moving || this.down ) return;
        if ( evt.target.nodeName !== "DIV" ) return;
        if ( !this.ref?.current ) return;
        let wgt = this.wgt = this.ref.current;

        wgt.setPointerCapture( evt.pointerId );
        wgt.onpointermove = (evt)=>this.onPointerMove(evt);
        wgt.onpointerup = (evt)=>this.onPointerUp(evt);
        evt.preventDefault();

        this.moveX = evt.clientX;
        this.moveY = evt.clientY;
        this.no = no;
        this.down = true;
        this.pointerId = evt.pointerId;
    }

    onPointerUp( evt ) {
        if ( !this.wgt ) return;

        this.wgt.releasePointerCapture( this.pointerId );
        this.wgt.onpointermove = null;
        this.wgt.onpointerup = null;
        evt.preventDefault();

        if ( this.down ) {
            this.down = false;
            this.block.setCurrent( this.no );
            return;
        }

        if ( this.moving ) {
            //window.dispatch( { actionType: "layoutEndMove", forPage: "SL" } );
            // TODO
            this.moving = false;
            superLayout.onEndMove();
        }
    }

    onPointerMove( evt ) {
        if ( this.down ) {
            let delta = Math.max( Math.abs( evt.clientX - this.moveX ), Math.abs( evt.clientY - this.moveY ) );
            if ( delta > 5 ) {
                if ( this.block.maximized ) {
                    app.showWarning( 'Нельзя перемещать в режиме "на весь экран"' );
                    this.down = false;
                    this.onPointerUp( evt );
                    return;
                }

                this.down = false;
                this.moving = true;
                this.moveX = evt.clientX;
                this.moveY = evt.clientY;

                superLayout.onStartMove( this.block, this.no, evt.clientX, evt.clientY );
            }
            evt.preventDefault();
            return;
        }
        
        if ( this.moving ) {
            superLayout.onMove( evt.clientX, evt.clientY );
            evt.preventDefault();
            return;
        }
    }

}

function PagesBlockComponent( { block, id } : {block: PagesBlock, id: any} ) {
    let state = block.revt.use();

    //  ref={w => {this.headerRef = w; this.checkSizes();

    let divRef : any = useRef( null );

    block.callbacks.ref = divRef;

    let mk_cls = visible => visible ? "dh-page-block" : "dh-page-block-invisible";
    let pages = block.pages.map( (p, no ) => 
        <div className={mk_cls(no === state.current)} key={p.pageId}>
            <PageComponent page={p}/>
        </div> 
    );

    return <div className="dh-sl-pages-block" ref={divRef} id={id}>
        <PagesBlockHeader state={state} block={block} />
        {pages}
    </div>;
}

function PagesBlockHeader( { state, block } ) {
    let tabs = block.pages.map( (page, no) => <PageTab key={page.pageUid} page={page} no={no} active={no === state.current} block={block} /> );

    return <div className="dh-sl-pages-headers">
        {tabs}
        {state.maximized && <MiniButton icon="minimize" tooltip="Вернуть обратно" onClick={()=>state.onMinimize()} />}
        <div style={{flex: "1 0 30px"}} />
        <PagesBlockSubmenu state={state} block={block} />
    </div>;
}

function PageTab( {page, no, active, block } : { page: Page, no: number, active: boolean, block: PagesBlock }) {
    let title = page.titleEvt.use();

    if ( !active ) {
        return <div key={page.pageId} className="dh-sl-tab" onPointerDown={evt=>block.callbacks.onPointerDown(evt,no)}>
            {title}
        </div>;
    }

    let cls = "dh-sl-tab dh-sl-tab-selected";
    let fixed = page.fixed;
    return <div key={page.pageId} className={cls} onPointerDown={evt=>block.callbacks.onPointerDown(evt,no)}>
        {title}
        { !fixed && <MiniButton icon="pin" tooltip="Зафиксировать" onClick={()=>{ page.stick(); block.notify(); }} /> }
        <MiniButton icon="cross" tooltip="Закрыть" onClick={()=>page.close()}/>
    </div>;
}

function PagesBlockSubmenu( { state, block } : { state: any, block: PagesBlock } ) {
    // let block = this.props.block;
    // let page = block.getActivePage();
    //let isRoot = block.parent.root !== undefined;
    let items = [
        <MenuItem key="close" icon="cross" text="Закрыть всё" onClick={()=>block.onClose()} />,
        !state.fixed ? 
            <MenuItem key="pin" icon="pin" text="Не закрывать без вкладок" onClick={()=>block.setFixed( true )} /> :
            <MenuItem key="unpin" icon="unpin" text="Закрыть без вкладок" onClick={()=>block.setFixed( false )} />,
        !state.maximized ? 
            <MenuItem key="maximize" icon="maximize" text="На весь экран" onClick={()=>superLayout.onMaximizeBlock(block)} disabled={state.isRoot}/> :
            <MenuItem key="minimize" icon="minimize" text="Вернуть обратно" onClick={()=>superLayout.restoreBlock()} />,
        <MenuItem key="close_active" icon="small-cross" text="Зактыть активную" disabled={state.hasPage} onClick={()=>block.onCloseCurrent()} />,
        <MenuItem key="fix_page" icon="pin" text="Зафиксировать активную" disabled={state.fixed} onClick={()=>block.setFixed(true)} />,
        <MenuDivider key="_div"/>,
    ]

    let i = 0;
    for ( let p of block.pages ) {
        let no = i++;
        items.push( 
            <MenuItem key={p.pageId} icon="document" text={<PageTitle page={p}/>} onClick={()=>block.setCurrent(no)}/>
        );
    }
    return <Popover autoFocus={false} minimal={true} position={Position.BOTTOM} content={<Menu>{items}</Menu>} className="dh-menubutton">
        <MiniButton icon="menu"/>
    </Popover>;
}

function PageTitle( { page } : { page: Page } ) {
    let title = page.titleEvt.use();
    return <span>{title}</span>;
}