import React, { useMemo, useRef } from "react";
import { BlockBase } from './SuperLayout';
import { ReactEvt } from "../utils";

//declare var superLayout: SuperLayout;
declare var PagesBlock;

export const NEAR = 30;

export class BoxBlock implements BlockBase {
    blocks: BlockBase[];
    weight: number;
    isVertical: boolean;
    state: any;
    parent?: BlockBase;
    revt: ReactEvt;

    moving = false;
    moveX = 0;
    moveY = 0;
    no = 0;

    constructor(blocks, isVertical) {
        this.blocks = blocks;
        this.weight = 100;
        this.isVertical = isVertical;
        this.state = {};
        this.revt = new ReactEvt( ()=>this.getState() );
        for (let block of this.blocks) {
            block.parent = this;
        }
    }

    addPage(page) {
        this.blocks[0].addPage(page);
        //this.blocks[ this.blocks.length - 1 ].addPage( page );
    }

    findBlockByPage(pageId) {
        for (let b of this.blocks) {
            let res = b.findBlockByPage(pageId);
            if (res) return res;
        }
        return null;
    }

    deleteBlock(block) {
        let pos = this.blocks.findIndex(b => b === block);
        if (pos < 0) {
            console.error("Can't delete block: ", this, block);
            return;
        }

        block.parent = null;

        this.blocks.splice(pos, 1);

        if (this.blocks.length === 0 && this.parent)
            this.parent.deleteBlock(this);
        else if (this.blocks.length === 1 && this.parent) {
            this.parent.replaceBlock(this, this.blocks[0]);
        } else {
            this.notify();
        }
    }

    render(props?) {
        if (this.isVertical)
            return <VBoxBlockComponent block={this} {...props} />;
        return <HBoxBlockComponent block={this} {...props} />;
    }

    notify() {
        this.revt.notify();
    }

    onResize(evt) {
        if (!this.isVertical)
            this.doResize(evt, (i => i.width), (i => i.x));

        else
            this.doResize(evt, (i => i.height), (i => i.y));
    }

    doResize(evt, getSize, getPos) {
        // console.log( "On resize call", evt )
        let totalSize = 0;
        let toalWeight = 0;
        for (let item of evt.items)
            totalSize += getSize(item);

        for (let block of this.blocks)
            toalWeight += block.weight;

        let prevScale = totalSize / toalWeight;

        let prevSize = getSize(evt.items[evt.no]);
        let newSize = getPos(evt) - getPos(evt.items[evt.no]) - 2;
        if (newSize < 5)
            newSize = 5;

        let newWeight = newSize / prevScale;
        this.blocks[evt.no].weight = newWeight;

        let prevRightSpace = 0;
        for (let item of evt.items.slice(evt.no + 1))
            prevRightSpace += getSize(item);

        let scale = (prevRightSpace + prevSize - newSize) / prevRightSpace;
        for (let block of this.blocks.slice(evt.no + 1)) {
            block.weight *= scale;
        }

        // console.log( "Resize values", { totalSize, toalWeight, totalSize, prevRightSpace, scale });
        this.notify();
    }

    getState() {
        return {
            type: this.isVertical ? "vbox" : "hbox",
            blocks: this.blocks,
            weight: this.weight
        };
    }

    getFBox(box) {
        if (this.isVertical) {
            let divs = this.getDivsPos(box.sy);
            let prev = 0;
            for (let i = 0; i < divs.length; ++i) {
                let y = divs[i];

                if (box.y < y || i == divs.length - 1) {
                    let box2 = { x: box.x, y: box.y - prev, sx: box.sx, sy: y - prev, inVbox: this.isVertical };
                    let fbox = this.blocks[i].getFBox(box2);
                    fbox.y += prev;
                    return fbox;
                }
                prev = y;
            }
        }
        else {
            let divs = this.getDivsPos(box.sx);
            let prev = 0;
            for (let i = 0; i < divs.length; ++i) {
                let x = divs[i];

                if (box.x < x || i == divs.length - 1) {
                    let box2 = { x: box.x - prev, y: box.y, sx: x - prev, sy: box.sy, inVbox: this.isVertical };
                    let fbox = this.blocks[i].getFBox(box2);
                    fbox.x += prev;
                    return fbox;
                }
                prev = x;
            }
        }
    }

    getFBox1(box) {
        if (this.isVertical)
            return this.getFBoxVertical(box);

        // Horizontal
        // Сверху ?
        if (box.y < NEAR) {
            return { x: 0, y: 0, sx: box.sx, sy: box.sy / 2, block: this, mode: "top" };
        }

        if (box.y > box.sy - NEAR) {
            return { x: 0, y: box.sy / 2, sx: box.sx, sy: box.sy / 2, block: this, mode: "bottom" };
        }

        let sx = box.sx / (this.blocks.length + 1);

        // У левого края?
        if (box.x < NEAR) {
            return { x: 0, y: 0, sx, sy: box.sy, block: this, mode: "left" };
        }

        // У правого края?
        if (box.x > box.sx - NEAR) {
            return { x: box.sx - sx, y: 0, sx, sy: box.sy, block: this, mode: "right" };
        }

        // Возле разделителей ?
        let divs = this.getDivsPos(box.sx);
        // console.log( "Divs", divs );
        for (let i = 0; i < divs.length; ++i) {
            let x = divs[i];
            if (Math.abs(x - box.x) < NEAR / 2 && i != divs.length - 1) {
                return {
                    x: Math.min(Math.max(x - sx / 2, 0), box.sx - sx),
                    y: 0,
                    sx,
                    sy: box.sy,
                    block: this, mode: "middle", no: i
                };
            }
            if (box.x < x || i == divs.length - 1) {
                let prev = (i > 0) ? divs[i - 1] : 0;
                let box2 = { x: box.x - prev, y: box.y, sx: x - prev, sy: box.sy, inVbox: this.isVertical };
                let fbox = this.blocks[i].getFBox(box2);
                fbox.x += prev;
                return fbox;
                // return { x: prev, y: 0, sx: x - prev, sy: box.sy }
            }
        }
        // unreachable
        // return { x: box.x, y: box.y, sx: 10, sy: 10 };
        return { x: 0, y: 0, sx: box.sx, sy: box.sy };
    }

    getFBoxVertical(box) {
        if (box.x < NEAR) {
            return { x: 0, y: 0, sx: box.sx / 2, sy: box.sy, block: this, mode: "left" };
        }

        if (box.x > box.sx - NEAR) {
            return { x: box.sx / 2, y: 0, sx: box.sx / 2, sy: box.sy, block: this, mode: "right" };
        }

        let sy = box.sy / (this.blocks.length + 1);

        // У левого края?
        if (box.y < NEAR) {
            return { x: 0, y: 0, sx: box.sx, sy, block: this, mode: "top" };
        }

        // У правого края?
        if (box.x > box.sx - NEAR) {
            return { x: 0, y: box.sy - sy, sx: box.sx, sy, block: this, mode: "bottom" };
        }

        // Возле разделителей ?
        let divs = this.getDivsPos(box.sy);
        // console.log( "Divs", divs );
        for (let i = 0; i < divs.length; ++i) {
            let y = divs[i];
            if (Math.abs(y - box.y) < NEAR / 2) {
                return {
                    x: 0,
                    y: Math.min(Math.max(y - sy / 2, 0), box.sy - sy),
                    sx: box.sx,
                    sy,
                    block: this, mode: "middle", no: i
                };
            }
            if (box.y < y || i == divs.length - 1) {
                let prev = (i > 0) ? divs[i - 1] : 0;
                let box2 = { x: box.x, y: box.y - prev, sx: box.sx, sy: y - prev, inVbox: this.isVertical };
                let fbox = this.blocks[i].getFBox(box2);
                fbox.y += prev;
                return fbox;
                // return { x: prev, y: 0, sx: x - prev, sy: box.sy }
            }
        }
        // unreachable
        // return { x: box.x, y: box.y, sx: 10, sy: 10 };
        return { x: 0, y: 0, sx: box.sx, sy: box.sy };
    }

    getDivsPos(size) {
        let res = this.blocks.map(b => b.weight);
        let toalWeight = res.reduce((a, b) => a + b, 0);
        let scale = size / toalWeight;
        let sum = 0;
        res = res.map((r, i) => { sum += r * scale; return sum; });
        return res;
    }

    movePage(fbox, movBlock, movNo) {
        let parent: BlockBase = this.parent as any;
        let newThis;

        let page = movBlock.delByNo(movNo);
        if (!page) {
            console.log("Bad move page");
            return;
        }

        if (this.isVertical) {
            switch (fbox.mode) {
                case "left": {
                    newThis = new BoxBlock([new PagesBlock([page]), this], false);
                    parent.replaceBlock(this, newThis);
                    break;
                }

                case "right": {
                    newThis = new BoxBlock([this, new PagesBlock([page])], false);
                    parent.replaceBlock(this, newThis);
                    break;
                }

                case "top": {
                    this.blocks.splice(0, 0, new PagesBlock([page]));
                    break;
                }

                case "bottom": {
                    this.blocks.splice(this.blocks.length, 0, new PagesBlock([page]));
                    break;
                }

                case "middle": {
                    this.blocks.splice(fbox.no + 1, 0, new PagesBlock([page]));
                    break;
                }

                default:
                    console.log("Unknown mode: ", fbox.mode);
            }
        } else {
            switch (fbox.mode) {
                case "top": {
                    newThis = new BoxBlock([new PagesBlock([page]), this], true);
                    parent.replaceBlock(this, newThis);
                    break;
                }

                case "bottom": {
                    newThis = new BoxBlock([this, new PagesBlock([page])], true);
                    parent.replaceBlock(this, newThis);
                    break;
                }

                case "left": {
                    this.blocks.splice(0, 0, new PagesBlock([page]));
                    break;
                }

                case "right": {
                    this.blocks.splice(this.blocks.length, 0, new PagesBlock([page]));
                    break;
                }

                case "middle": {
                    this.blocks.splice(fbox.no + 1, 0, new PagesBlock([page]));
                    break;
                }

                default:
                    console.log("Unknown mode: ", fbox.mode);
            }
        }
    }

    replaceBlock(prevBlock, newBlock) {
        for (let i = 0; i < this.blocks.length; ++i) {
            if (this.blocks[i] === prevBlock) {
                this.blocks[i] = newBlock;
                newBlock.parent = this;
                this.notify();
                return;
            }
        }
    }

    delPage(pageId) {
        for (let b of this.blocks) {
            b.delPage(pageId);
        }
    }

    setPageVisible( pageId ) : void {
        this.blocks.forEach( b => b.setPageVisible( pageId ) );
    }

    // unused
    replaceOrAddPage(pageId, page): void { };
    checkEmpty(): void { };
}

class BB_Callbacks {
    moving = false;
    ref?: { current: HTMLElement };
    wgt?: HTMLElement;
    moveX = 0;
    moveY = 0;
    pointerId: any;
    no = 0;
    block: BoxBlock;

    constructor( block, ref ) {
        this.ref = ref;
        this.block = block;
    }

    getEvtPos( evt ) {
        let w = (this.wgt || this.ref?.current);
        if ( !w ) return [ 0, 0 ];

        let rects = w.getClientRects();
        return [ evt.clientX - rects[0].x, evt.clientY - rects[0].y ];
    }

    onPointerDown( evt, no ) {
        if ( this.moving ) return;
        if ( !this.ref?.current ) return;
        let wgt = this.wgt = this.ref.current;

        console.log( "Pointer down" );

        wgt.setPointerCapture( evt.pointerId );
        wgt.onpointermove = (evt)=>this.onPointerMove(evt);
        wgt.onpointerup = (evt)=>this.onPointerUp(evt);
        evt.preventDefault();
        evt.stopPropagation();
        let [moveX, moveY] = this.getEvtPos( evt );

        this.moveX = moveX;
        this.moveY = moveY;
        this.no = no;
        this.moving = true;
        this.pointerId = evt.pointerId;
        this.block.notify();
    }

    onPointerMove( evt ) {
        if ( !this.moving ) return;

        console.log( "Pointer move" );

        let [moveX, moveY] = this.getEvtPos( evt );
        this.moveX = moveX;
        this.moveY = moveY;
        evt.stopPropagation();
        evt.preventDefault();
        this.block.notify();
    }

    onPointerUp( evt : PointerEvent ) {
        if ( !this.moving || !this.wgt) return;

        console.log( "Pointer up" );

        this.wgt.releasePointerCapture( this.pointerId );
        this.wgt.onpointermove = null;
        this.wgt.onpointerup = null;
        evt.stopPropagation();
        evt.preventDefault();
        this.moving = false;

        this.block.onResize( {
            no: this.no,
            x: evt.clientX,
            y: evt.clientY,
            items: this.getCurSizes()
        } );
    }

    getCurSizes() {
        if ( !this.wgt ) return [];

        let elems = this.wgt.querySelectorAll( ":scope > div.dh-sl-boxblock" );

        return [ ...elems.values() ].map( elem => elem.getClientRects()[0] );
    }
}
    
function VBoxBlockComponent( { block, id } : { block: BoxBlock, id: any} ) {
    let state = block.revt.use();
    let divRef = useRef(null);

    let callbacks = useMemo( ()=>new BB_Callbacks(block, divRef), [ block, divRef ] );

    let blocks : any[] = [];
    let i = 0;
    for ( let b of block.blocks ) {
        let flex = `${b.weight} 0 0px`;

        blocks.push( <div key={"b" + i} className="dh-sl-boxblock dh-sl-boxblock-v" style={{flex}}>{b.render(null)}</div> );
        if ( i < block.blocks.length - 1 ) {
            let no = i;
            blocks.push( <div key={"s" + i} className="dh-sl-boxsizer-v" onPointerDown={evt=>callbacks.onPointerDown(evt, no)}></div> );
        }
        i++;
    }

    let ptrStyle = callbacks.moving ? {
        display: "block",
        top: (callbacks.moveY - 2) + "px"
    } : {
        display: "none"
    };

    // onPointerMove={this.onPointerMove} onPointerUp={this.onPointerUp}
    return <div className="dh-sl-vbox" ref={divRef} id={id}>
        {blocks}
        <div className="dh-sl-boxptr-v" style={ptrStyle}/>
    </div>;
}

function HBoxBlockComponent( { block, id } : { block: BoxBlock, id: any} ) {
    let state = block.revt.use();
    let divRef = useRef(null);

    let callbacks = useMemo( ()=>new BB_Callbacks(block, divRef), [ block, divRef ] );

    let blocks : any[] = [];
    let i = 0;
    for ( let b of block.blocks ) {
        let flex = `${b.weight} 0 0px`;

        blocks.push( <div key={"b" + i} className="dh-sl-boxblock dl-sh-boxblock-h" style={{flex}}>{b.render(null)}</div> );
        if ( i < block.blocks.length - 1 ) {
            let no = i;
            blocks.push( <div key={"s" + i} className="dh-sl-boxsizer-h" onPointerDown={evt=>callbacks.onPointerDown(evt, no)}></div> );
        }
        i++;
    }
    let ptrStyle = callbacks.moving ? {
        display: "block",
        left: (callbacks.moveX - 2) + "px"
    } : {
        display: "none"
    };

    // onPointerMove={this.onPointerMove} onPointerUp={this.onPointerUp}
    return <div className="dh-sl-hbox" ref={divRef} id={id}>
        {blocks}
        <div className="dh-sl-boxptr-h" style={ptrStyle}/>
    </div>;
}

