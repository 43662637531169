import { Intent, OverlayToaster, Toast, Toast2, Toaster } from "@blueprintjs/core";
import { app } from "../App";
import { DhDialog } from "./DhDialog";
import { DhNotification, Notifications } from "../Notifications";
import { SuperLayoutRoot, superLayout } from "../layout/SuperLayout";
import { DhMenu } from "./DhMenu";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { memo } from "react";

export function Root() {
    // let dialogKeys = app.dialogsRevt.use();

    // let dialogs = dialogKeys.map( pageId => {
    //     let page = app.pages[ pageId ];
    //     return <DhDialog page={page} key={pageId} /> 
    // } );

    // console.log( "Dialog keys: ", dialogKeys );
    // console.log( "Dialogs: ", dialogs );

//    let toasts = rm.messages.map( m => <Toast key={m.id} message={m.text} intent={m.highlight} onDismiss={()=>rm.destroyMessage(m.id)} /> );
//let pages = rm.pages.map( (p,i) => 
//    <Tab id={p.pageId} key={p.pageId} title={p.getTitle() || "[Empty]" }
//        panel={<PageComponent model={p} key={p.pageId} />}
//    />
//);
//<Tabs id="rootTabs" selectedTabId={rm.currentPageId} onChange={newTabId=>rm.onPageChange(newTabId)}>
//{pages}
//</Tabs>

//        { superLayout.render() }

    return <div className="dh-root">
        <DhMenu />
        <SuperLayoutRoot />
        <Dialogs/>
        <DhToaster notifs={app.notifications} />
    </div>;
}

function _Dialogs() {
    let dialogKeys = app.dialogsRevt.use();

    let dialogs = dialogKeys.map( pageId => {
        let page = app.pages[ pageId ];
        return <DhDialog page={page} key={pageId} /> 
    } );

    return <>{dialogs}</>;
}

const Dialogs = memo( _Dialogs );

const INTENTS = {
    "error": Intent.DANGER,
    "dander": Intent.DANGER,
    "warning": Intent.WARNING,
    "success": Intent.SUCCESS,
    "good": Intent.SUCCESS
}

function getIntent( h: string ) {
    return INTENTS[h] || Intent.NONE;
}

export function _DhToaster ( { notifs } : { notifs: Notifications } ) {
    let ntList = notifs.revt.use() as DhNotification[];

    let toasts = ntList.map( m => <Toast2 key={m.id} message={m.text} intent={getIntent(m.highlight)} onDismiss={()=>notifs.onDestroy(m.id)} /> );
    return <OverlayToaster position="top-right">
        {toasts}
    </OverlayToaster>
}

const DhToaster = memo( _DhToaster );